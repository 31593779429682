import {nextTick, ref} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import {dictType, getReview, getReviewReport, listReviewAward, myPaymentByReviewId, schoolList, universityList} from '@/api/api'
import * as echarts from "echarts";
import {useMessage} from "naive-ui";

let echart = echarts;

export default () => {
    const router = useRouter()
    const route = useRoute()
    const message = useMessage();

    const loaded = ref(false)
    const model = ref({})
    const reportData = ref({})
    const uniqueUniversityList = ref([]);
    const report = ref([
        {id: "2", title: '学科成绩优异项', title2: '成绩优异', universityList: []},
        {id: "3", title: '学科竞赛奖项', title2: '五大竞赛', universityList: []},
        {id: "4", title: '全国竞赛活动奖项', title2: '全国竞赛', universityList: []},
        {id: "5", title: '全省竞赛活动奖项', title2: '全省竞赛', universityList: []},
        {id: "6", title: '其他竞赛活动奖项', title2: '其他竞赛', universityList: []},
        {id: "7", title: '表彰类活动奖项', title2: '荣誉表彰', universityList: []},
        {id: "8", title: '创新潜质项', title2: '创新潜质项', universityList: []},
    ]);
    const wouldUniversityList = ref([]);

    const hasAwardCount = ref(0);
    const awardTypes = ref(
        [
            {name: '五大学科', name2: '五大学科竞赛活动奖项', chartName: "五大学科竞赛", id: '3', inputName: false, awards: []},
            {name: '全国竞赛', name2: '全国竞赛活动奖项', chartName: "全国竞赛活动", id: '4', inputName: false, awards: []},
            {name: '全省竞赛', name2: '全省竞赛活动奖项', chartName: "全省竞赛活动", id: '5', inputName: false, awards: []},
            {name: '其他竞赛', name2: '其他竞赛活动奖项', chartName: "其他竞赛活动", id: '6', inputName: false, awards: []},
            {name: '表彰类', name2: '荣誉表彰奖项', chartName: "荣誉表彰奖项", id: '7', inputName: false, awards: []},
            {name: '创新潜质', name2: '创新潜质', chartName: "创新潜质项", id: '8', inputName: true, awards: []},
            {name: '综评档案优势', name2: '综评档案优势得分项', chartName: "综评档案优势", id: '9', inputName: true, awards: []},
        ]
    )

    const types = ref({
        grades: [],
        physical: [],
        primary: [],
        elective: [],
        english: [],
        school: [],
        score: [],
        competition: [],
        award: [],
        award_2: [],
        award_3: [],
        rank: [],
        // 高校信息
        selectOptions1: [],
        selectOptions2: [],
        selectOptions3: [],
        selectOptions4: []
    })

    const id = ref(route.query.id)

    function initChart2() {
        // console.log('initChart', awardTypes);
        // 把配置和数据放这里
        let option = {
            xAxis: {
                type: "value",
                minInterval: 1,
            },
            tooltip: {
                trigger: "axis"
            },
            grid: {
                left: '2%',
                right: '2%',
                bottom: '2%',
                top: '2%',
                containLabel: true,
                textStyle: {
                    color: "#fff"
                },
            },
            yAxis: {
                type: "category",
                data: [],
                axisLine: {
                    show: false
                },
                axisTick: {
                    show: false
                },
                axisLabel: {
                    formatter: function (value, index) {
                        let i = Math.abs(index - 11);
                        if (i < 10) {
                            i = "0" + i;
                        }
                        let indexStyle = "index2";
                        let nameStyle = "name2";
                        if (index % 2 === 0) {
                            indexStyle = "index1";
                            nameStyle = "name1";
                        }
                        const arr = [
                            `{${indexStyle}|${i}}`,
                            `{${nameStyle}|${value}}`,
                        ];
                        return arr.join('');
                    },

                    rich: {
                        index1: {
                            fontSize: 12,
                            fontWeight: 'bold',
                            backgroundColor: "#FFF5F4",
                            align: 'center',
                            color: '#FACBC6',
                            padding: [3, 3, 3, 3],
                        },
                        name1: {
                            fontSize: 12,
                            width: 120,
                            backgroundColor: "#FFF5F4",
                            fontWeight: 'bold',
                            padding: [3, 3, 3, 3],
                        },
                        index2: {
                            fontSize: 12,
                            fontWeight: 'bold',
                            backgroundColor: "#EFEFEF",
                            color: "#D5D2D2",
                            align: 'center',
                            padding: [3, 3, 3, 3],
                        },
                        name2: {
                            fontSize: 12,
                            width: 120,
                            backgroundColor: "#EFEFEF",
                            fontWeight: 'bold',
                            padding: [3, 3, 3, 3],
                        },
                    }
                }
            },
            series: [
                {
                    data: [],
                    type: "bar",
                    itemStyle: {
                        normal: {
                            barBorderRadius: [0, 10, 10, 0],
                            color: function (param) {
                                let colorList = ["#00c9fa", "#00faff"];
                                let x = '';
                                param.dataIndex % 2 === 0 ? x = 1 : x = 2;
                                if (x === 1) {
                                    return colorList[0];
                                } else {
                                    return colorList[1]
                                }
                            }
                        }
                    },
                    smooth: true,
                }
            ]
        };
        let hasCount = 0;
        awardTypes.value.map(t => {
            if (t.id === '9') {
                ["体育素养活动", "艺术素养活动", "社会实践与志愿服务", "研究性学习成果", "通用技术作品"].map((name, index) => {
                    let v = t.awards.filter(i => i.advantageType === (index + 1).toString()).length;
                    option.yAxis.data.push(name)
                    option.series[0].data.push(v);
                    if (v > 0) {
                        hasCount += 1;
                    }
                })
            } else {
                let v = t.awards.length;
                option.yAxis.data.push(t.chartName);
                option.series[0].data.push(v);
                if (v > 0) {
                    hasCount += 1;
                }
            }
        })
        hasAwardCount.value = hasCount;

        option.yAxis.data.reverse();
        option.series[0].data.reverse();
        nextTick(() => {
            let chart = echart.init(document.getElementById("myEcharts2"), "light");
            chart.setOption(option);
            window.onresize = function () {
                //自适应大小
                chart.resize();
            };
        })
    }

    function getAwardScoreResult() {
        if (reportData.value.Score_Result >= 90) {
            return "优秀"
        } else if (reportData.value.Score_Result >= 70) {
            return "良好"
        } else if (reportData.value.Score_Result >= 55) {
            return "一般"
        } else {
            return "较差";
        }
    }

    function getAwardsByType(id) {
        return awardTypes.value.find(i => i.id === id).awards;
    }

    function getCountdown() {
        //高考倒计时天数
        let now = new Date();
        let year = now.getFullYear() + 1;
        let end = new Date(year + "-06-07");
        let leftTime = end.getTime() - now.getTime();
        return Math.floor(leftTime / 1000 / (60 * 60 * 24));
    }

    function getSuggestedAward() {
        let result = [];
        // console.log('getSuggestedAward', awardTypes.value);
        if (awardTypes.value[0].awards.length <= 1) {
            result.push("五大学科竞赛奖项")
        }
        if (awardTypes.value[1].awards.length <= 2) {
            result.push("全国竞赛活动奖项")
        }
        if (awardTypes.value[2].awards.length <= 2) {
            result.push("全省竞赛活动奖项")
        }
        if (awardTypes.value[6].awards.filter(i => i.advantageType === "1").length <= 3) {
            result.push("体育素养活动")
        }
        if (awardTypes.value[6].awards.filter(i => i.advantageType === "2").length <= 3) {
            result.push("艺术素养活动")
        }
        if (awardTypes.value[6].awards.filter(i => i.advantageType === "3").length <= 2) {
            result.push("社会实践与志愿服务")
        }
        if (awardTypes.value[6].awards.filter(i => i.advantageType === "4").length <= 3) {
            result.push("研究性学习成果")
        }
        if (awardTypes.value[6].awards.filter(i => i.advantageType === "5").length <= 3) {
            result.push("通用技术作品")
        }
        let resultTxt = "";
        result.map(i => {
            resultTxt += `《${i}》`;
        })
        return resultTxt;
    }

    function getMatchValue(uid) {
        let v = 0;

        let u = getUniversity(uid);
        //任意综评项符合，+1
        for (let repo of report.value) {
            if (checkUniversity(u.dictLabel, repo)) {
                v += 1;
            }
        }

        if (v > 5) {
            return 5;
        } else {
            return v;
        }
    }

    function getScoreLine(item) {
        if (!item.sc_line_config) {
            return "";
        }
        if (getTypeName(model.value.basePrimarySubject, 'primary') === '物理') {
            return item.sc_line_config.physics;
        } else {
            return item.sc_line_config.history;
        }
    }

    function checkScMatch(item, type, v) {
        if (!item.sc_prob_config) {
            return '';
        }
        let t
        if (getTypeName(model.value.basePrimarySubject, 'primary') === '物理') {
            t = "physics";
        } else {
            t = "history";
        }
        if (type === '冲') {
            if (scRangeCheck(item, t + "_60_under", v)) {
                return '低';
            } else if (scRangeCheck(item, t + "_60", v)) {
                return '高';
            } else if (scRangeCheck(item, t + "_70", v)) {
                return '较高';
            } else {
                return '';
            }
        } else if (type === '稳') {
            if (scRangeCheck(item, t + "_80", v)) {
                return '高';
            } else if (scRangeCheck(item, t + "_90", v)) {
                return '较高';
            } else {
                return '';
            }
        } else {
            return '';
        }
    }

    function scRangeCheck(item, key, v,) {
        let prob = item.sc_prob_config[key];
        if (!prob) {
            return false;
        }
        let prob_range = prob.split('-');
        // console.log('prob_range', prob_range);
        if (prob_range[0] === '') {
            prob_range[0] = 0;
        }
        if (prob_range[1] === '') {
            prob_range[1] = 10000;
        }
        let startValue = parseInt(prob_range[0]);
        let endValue = parseInt(prob_range[1]);
        return v >= startValue && v <= endValue;
    }

    function getMatchUniversityList(type) {
        let list = types.value.selectOptions1;

        if (type === 1) {
            //冲的学校
            return list.filter(i => {
                return i.match1 !== '';
            });
        } else if (type === 2) {
            //稳的学校
            return list.filter(i => {
                return i.match2 !== '';
            });
        }
    }

    function getWouldUniversityList() {
        let list = types.value.selectOptions1;
        //意向院校
        let uids = [];
        if (model.value.baseUniversityId.indexOf(',')) {
            model.value.baseUniversityId.split(',').map(i => {
                uids.push(parseInt(i));
            })
        } else {
            uids.push(parseInt(model.value.baseUniversityId));
        }
        let result = [];
        list.map(i => {
            if (uids.indexOf(i.dictValue) > -1) {
                let item = JSON.parse(JSON.stringify(i));
                let matchCount = 0;
                report.value.map(repo => {
                    let match = checkUniversity(i.dictLabel, repo);
                    if (match) {
                        matchCount += 1;
                    }
                    item[repo.id] = match;
                })
                item.matchCount = matchCount;
                result.push(item);
            }
        })
        wouldUniversityList.value = result;
    }

    function getFile(file) {
        return process.env.VUE_APP_BASE_API + file;
    }

    function checkUniversity(uName, repo) {
        return repo.universityList.findIndex(i => i.name === uName) > -1;
    }

    function buildAwardList(item, university) {
        let awards = [];
        let awardType = awardTypes.value.find(i => i.id === item.id);
        // console.log(' item', item);
        // console.log(' university awards', university.awards);
        // console.log(' awards', awardType);
        if (awardType) {
            awardType.awards.map(award => {
                //需要与院校匹配
                let universityAwardIndex = university.awards.findIndex(u => {
                    //创新潜质填写就通过
                    if (award.reviewType === 8) {
                        return true;
                    }
                    //其他的判断奖项等级
                    else if (u.reviewType === award.reviewType && u.raceCategory === award.raceCategory) {
                        if (u.raceConfig !== '') {
                            let raceConfigs = JSON.parse(u.raceConfig);
                            for (let raceConfig of raceConfigs) {
                                if (award.raceLevel === raceConfig.level) {
                                    //表彰类忽略级别因素
                                    if (award.reviewType === 7) {
                                        return true;
                                    } else if (raceConfig.rank.indexOf(award.raceRank) > -1) {
                                        return true;
                                    }
                                }
                            }
                        } else {
                            return true;
                        }
                    }
                })
                if (universityAwardIndex > -1) {
                    let name1 = getTypeName(award.raceCategory, 'competition', award.raceName);
                    let name2 = getTypeName(award.raceLevel, 'award');
                    if (award.reviewType === 9 && award.advantageType <= 3) {
                        name2 = getTypeName(award.raceLevel, 'award_2');
                    }
                    if (award.reviewType === 9 && award.advantageType > 3) {
                        name2 = getTypeName(award.raceLevel, 'award_3');
                    }
                    let name3 = getTypeName(award.raceRank, 'rank')
                    let name = "";
                    if (name1) {
                        name += name1;
                    }
                    if (name2) {
                        name += name2;
                    }
                    if (name3) {
                        name += name3;
                    }
                    awards.push(name);
                }
            })
        }
        return awards.join(',');
    }

    function getAdvantageType(type) {
        let name = "";
        switch (type) {
            case "1":
                name = "体育特长项";
                break;
            case "2":
                name = "艺术特长项";
                break;
            case "3":
                name = "社会活动实践";
                break;
            case "4":
                name = "研究性学习成果";
                break;
            case "5":
                name = "通用技术作品";
                break;
        }
        return name;
    }

    function buildAwardLevel(award) {
        let result = "";
        if (award.reviewType === 9 && award.advantageType <= 3) {
            result += getTypeName(award.raceLevel, 'award_2');
        } else if (award.reviewType === 9 && award.advantageType > 3) {
            result += getTypeName(award.raceLevel, 'award_3');
        } else {
            result += getTypeName(award.raceLevel, 'award');
        }
        return result;
    }

    function buildAwardRank(award) {
        return getTypeName(award.raceRank, 'rank');
    }

    function getTypeName(val, typeName, res) {
        if (!res) {
            res = "";
        }
        let names = [];
        let vals = [];
        if (val) {
            if (val.toString().indexOf(',') > -1) {
                // console.log('数组类型', val);
                vals = val.split(',');
            } else {
                vals.push(val);
            }
            for (let v of vals) {
                if (types && types.value[typeName]) {
                    let item = types.value[typeName].find(i => {
                        return i.dictValue.toString() === v.toString()
                    });
                    if (item) {
                        names.push(item.dictLabel);
                    }
                }
            }
        }
        let result = names.join(',');
        if (result) {
            return result;
        } else {
            return res;
        }
    }

    function getUniversity(id) {
        return types.value.selectOptions1.find(i => i.dictValue === id)
    }

    function toPage(path) {
        router.push(path)
    }

    async function load() {
        let paymentByReview = await myPaymentByReviewId(id.value);
        // console.log('myPaymentByReviewId', paymentByReview);
        if (!paymentByReview.data || paymentByReview.data.paid !== 1) {
            message.error("综评尚未支付");
            return;
        }

        let reviewRes = await getReview(id.value);
        // console.log('review:', reviewRes);
        model.value = reviewRes.data;
        let awardRes = await listReviewAward({
            reviewId: model.value.id,
            pageNum: 1,
            pageSize: 1000
        })
        // console.log('awardRes:', awardRes);
        let awards = awardRes.rows;
        for (let awardType of awardTypes.value) {
            let reviewAwards = awards.filter(i => {
                return i.reviewType.toString() === awardType.id
            });
            awardType.awards = awardType.awards.concat(reviewAwards);
        }

        let universityRes = await universityList({
            pageNum: 1,
            pageSize: 1000
        })
        universityRes.rows.forEach((item, i) => {
            let option = {
                dictLabel: item.name,
                dictValue: item.id,
                dictIntro: item.intro,

                id: item.id,
                name: item.name,
                intro: item.intro,
                feature: item.feature,
                scoreDesc: item.scoreDesc,

                sc_line_config: JSON.parse(item.sc_line_config),
                sc_prob_config: JSON.parse(item.sc_prob_config),

                awards: item.awards
            };
            types.value.selectOptions1.push(option)
        });

        let schoolRes = await schoolList({
            pageNum: 1,
            pageSize: 1000
        })
        schoolRes.rows.forEach((item, i) => {
            types.value.selectOptions3.push({
                dictLabel: item.name,
                dictValue: item.id,
            })
        });

        let reportRes = await getReviewReport(id.value)
        let data = reportRes.data;
        reportData.value = data;
        // console.log('review report', reportRes, [...new Set(data.Match2)]);
        let allUniversity = [];
        let matchList = [
            [...new Set(data.Match2)],
            [...new Set(data.Match3)],
            [...new Set(data.Match4)],
            [...new Set(data.Match5)],
            [...new Set(data.Match6)],
            [...new Set(data.Match7)],
            [...new Set(data.Match8)],
            [...new Set(data.Match9)],
        ];
        for (let i = 0; i < 8; i++) {
            matchList[i].map(uId => {
                let u = getUniversity(uId);
                if (u) {
                    if (allUniversity.findIndex(i => i.id === uId) === -1) {
                        allUniversity.push(u);
                    }
                    report.value[i].universityList.push(u)
                }
            })
        }
        uniqueUniversityList.value = allUniversity;
        // console.log('report result:', report, allUniversity);

        //冲稳情况
        let baseScore = parseInt(model.value.baseScore);
        types.value.selectOptions1.map(i => {
            i.match1 = checkScMatch(i, '冲', baseScore);
            i.match2 = checkScMatch(i, '稳', baseScore);
        })

        //意向院校
        getWouldUniversityList();

        //院校成绩分析
        loaded.value = true;
        await nextTick(() => {
            initChart2();
        })
    }

    dictType('/school_grades').then(res => {
        types.value.grades = res.data
    })
    dictType('/physical_level').then(res => {
        types.value.physical = res.data
    })
    dictType('/primary_subject').then(res => {
        types.value.primary = res.data
    })
    dictType('/elective_subject').then(res => {
        types.value.elective = res.data
    })
    dictType('/english_level').then(res => {
        types.value.english = res.data
    })
    dictType('/hz_school_level').then(res => {
        types.value.school = res.data
    })
    dictType('/hz_score_level').then(res => {
        types.value.score = res.data
    })
    dictType('/competition_category').then(res => {
        types.value.competition = res.data
    })
    dictType('/award_level').then(res => {
        types.value.award = res.data
    })
    dictType('/award_level_2').then(res => {
        types.value.award_2 = res.data
    })
    dictType('/award_level_3').then(res => {
        types.value.award_3 = res.data
    })
    dictType('/award_rank').then(res => {
        types.value.rank = res.data
    })


    dictType('/majors_category').then(res => {
        res.data.forEach((item, i) => {
            types.value.selectOptions2.push({
                dictLabel: item.dictLabel,
                dictValue: item.dictValue
            })
        });
    })
    dictType('/review_type').then(res => {
        res.data.forEach((item, i) => {
            types.value.selectOptions4.push({
                dictLabel: item.dictLabel,
                dictValue: item.dictValue
            })
        });
    })

    return {
        router,
        route,
        loaded,
        load,
        model,
        reportData,
        uniqueUniversityList,
        report,
        wouldUniversityList,
        hasAwardCount,
        awardTypes,
        types,
        id,
        initChart2,
        getAwardScoreResult,
        getAwardsByType,
        getCountdown,
        getSuggestedAward,
        getMatchValue,
        getScoreLine,
        checkScMatch,
        scRangeCheck,
        getMatchUniversityList,
        getWouldUniversityList,
        getFile,
        checkUniversity,
        buildAwardList,
        getAdvantageType,
        buildAwardLevel,
        buildAwardRank,
        getTypeName,
        getUniversity,
        toPage,
    }
}