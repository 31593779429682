import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'
import Longin from '@/views/Longin'
import Home from '@/views/Home'
import School from '@/views/School'
import SchoolDetail from '@/views/School/detail'
import Me from '@/views/Me'
import Order from '@/views/Order'
import Point from '@/views/Point'
import Settlement from '@/views/Settlement'
import Report from '@/views/Report'
import ReportPrint from '@/views/Report/indexPrint'
import Evaluation from '@/views/Evaluation'
import Payment from '@/views/Settlement/payment'
import MyPayment from '@/views/Order/payment'
import PaymentSuccess from '@/views/Settlement/paymentSuccess'
import EvaluationIntro from '@/views/Evaluation/intro'

const routes = [
    {
        path: "/",
        redirect: '/home',
    },
    {
        path: "/login",
        component: Longin,
    },
    {
        path: "/home",
        component: Home,
    },
    {
        path: "/school/:id",
        component: School,
    },
    {
        path: "/detail",
        component: SchoolDetail,
    },
    {
        path: "/me",
        component: Me,
    },
    {
        path: "/order",
        component: Order,
    },
    {
        path: "/my/payment",
        component: MyPayment,
    },
    {
        path: "/point",
        component: Point,
    },
    {
        path: "/settlement",
        component: Settlement,
    },
    {
        path: "/Report",
        component: Report,
    },
    {
        path: "/ReportPrint",
        component: ReportPrint,
    },
    {
        path: "/settlement/payment",
        component: Payment,
    },
    {
        path: "/settlement/payment/success",
        component: PaymentSuccess,
    },
    {
        path: "/evaluation",
        component: Evaluation,
    },
    {
        path: "/evaluation/intro",
        component: EvaluationIntro,
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})


export default router
