function change() {
  const html = document.querySelector('html')
  const baseSize = 32
  // 获取根元素 fontSize 标准，屏幕宽度 / 10。（以 Iphone 为例 Iphone 6 屏幕宽度为 375，则标准 fontSize 为 37.5）
  const scale = document.documentElement.clientWidth / 1920
  let size = (baseSize * Math.min(scale, 2))
  if (size <= 24) {
    size = 24
  }
  // 设置页面根节点字体大小
  html.style.fontSize = size + 'px'
}
export const useREM = () => {

  // 监听 html 文档被解析完成的事件
  window.addEventListener('resize', () => {
    change()
  })
}

;(function (win, doc) {
  useREM()
  change()
})(window, document)
