<template>
  <n-layout>
    <n-layout-content>
      <div class="bg h-full">
        <Header/>
        <div style="min-height: calc(100% - 5.875rem); padding-bottom: 1.25rem;">
          <div class="breadcrumb">
            <n-breadcrumb>
              <n-breadcrumb-item>个人中心</n-breadcrumb-item>
            </n-breadcrumb>
          </div>
          <div class="container">
            <div class="area top">
              <div class="avatar">
                <img src="../../assets/images/defult.png">
              </div>
              <div class="list flex">
                <div class="item" @click="toPage('/order')">
                  <img src="../../assets/images/kc.png">
                  <p>我的综评</p>
                </div>
                <div class="item" @click="toPage('/my/payment')">
                  <img src="../../assets/images/wddd.png">
                  <p>我的订单</p>
                </div>
                <div class="item" @click="toPage('/point')">
                  <img src="../../assets/images/wdzs.png">
                  <p>我的积分</p>
                </div>
              </div>
            </div>
            <div class="area center">
              <div class="list">
                <div class="item flex" @click="invite">
                  <img src="../../assets/images/grxx.png">
                  <span>邀请用户（赠送积分）</span>
                </div>
                <div class="item flex" @click="customer">
                  <img src="../../assets/images/kf.png">
                  <span>联系客服</span>
                </div>
              </div>
            </div>
            <div class="area bottom">
              <div class="list">
                <div class="item flex" @click="logout">
                  <img src="../../assets/images/tcdl.png">
                  <span>退出登录</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    </n-layout-content>
  </n-layout>
</template>

<script setup>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import {useRouter} from 'vue-router'
import {removeToken} from "@/util/auth";
import {useDialog} from 'naive-ui'
import {onMounted, ref} from "vue";
import {myInfo} from "@/api/api";

const dialog = useDialog()
const router = useRouter()
const model = ref('')

onMounted(() => {
  myInfo().then(res => {
    model.value = res
  })
})


function logout() {
  removeToken();
  router.push('/home');
}

function customer() {
  dialog.info({
    title: "客服联系方式",
    content: "400-655-7288 / 025-58229930",
  })
}

function invite() {
  dialog.info({
    title: "我的邀请链接",
    content: process.env.VUE_APP_CLIENT_URL + "/home?inviteId=" + model.value.id,
  })
}

function toPage(path) {
  router.push(path)
}
</script>

<style lang="scss" scoped>
.breadcrumb {
  width: 37.5rem;
  margin: 0 auto;
  margin-top: 0.75rem;
}

.container {
  max-width: 37.5rem;
  min-height: 21.0625rem;
  margin: 0 auto;
  margin-top: 0.5rem;
  background: #FFFFFF;
  box-shadow: 0px 0 0.125rem 0px rgba(0, 0, 0, 0.09);
  border-radius: 0.25rem;
  padding-top: 3.125rem;

  .area {
    width: 18.125rem;
    background: #F9F9FC;
    border-radius: 0.1875rem;
    margin: 0 auto;

    &.top {
      position: relative;
      padding-top: 2.3125rem;
      margin-bottom: 0.75rem;

      padding-bottom: 0.75rem;

      .list {
        justify-content: center;

        .item {
          width: 33.33%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          img {
            width: 1rem;
          }

          p {
            font-size: 0.5rem;
            font-family: SourceHanSansCN, SourceHanSansCN;
            color: #666666;
            margin-top: 0.3125rem;
          }
        }
      }
    }

    .list {
      .item {
        cursor: pointer;
      }
    }

    &.center,
    &.bottom {
      .list {
        padding-left: 1.125rem;
        padding-right: 0.90625rem;

        .item {
          font-size: 0.5rem;
          font-family: SourceHanSansCN, SourceHanSansCN;
          color: #061B5C;
          display: flex;
          align-items: center;
          padding: 0.5625rem 0;

          img {
            width: 0.625rem;
            height: 0.625rem;
            margin-right: 0.09375rem;
          }
        }
      }
    }

    &.center {
      .item {
        &:first-child {
          border-bottom: 1px solid #ECECF3;
        }
      }
    }

    &.bottom {
      margin-top: 0.75rem;
    }

    .avatar {
      position: absolute;
      left: 50%;
      top: 0;
      transform: translate(-50%, -50%);
      width: 2.625rem;
      height: 2.625rem;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.mt-25 {
  margin-top: 0.78125rem;
}

.mt-16 {
  margin-top: 0.5rem;
}
</style>
