<template>
  <n-layout>
    <n-layout-content>
      <div class="bg h-full">
        <Header/>
        <div style="min-height: calc(100% - 5.875rem); padding-bottom: 1.25rem;">
          <div class="breadcrumb">
            <n-breadcrumb>
              <n-breadcrumb-item @click="toPage('/me')" separator=">">个人中心</n-breadcrumb-item>
              <n-breadcrumb-item>我的综评</n-breadcrumb-item>
            </n-breadcrumb>
          </div>
          <div class="container">
            <div class="d-title flex">
              <img src="../../assets/images/wddd.png">
              <p>我的综评</p>
            </div>
            <div class="list">
              <div class="item" v-for="item in list">
                <div class="row flex">
                  <div class="left" @click="goDetail(item)" v-if="item.type===2">
                    分项综评档案
                  </div>
                  <div class="left" @click="goDetail(item)" v-if="item.type===1">
                    <span v-if="item.baseName">{{ item.baseName }} 的 </span>综合素质档案
                  </div>
                  <!--                  <div class="right">-->
                  <!--                    <span>¥</span>4980-->
                  <!--                  </div>-->
                </div>
                <div class="row-2 flex">
                  <div class="left">
                    报告ID：{{ item.id }}
                    <div>{{ item.createTime }}</div>
                  </div>
                  <div class="right btn" @click="goReport(item)" v-if="item.type===1">
                    获取您的完整测评报告
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    </n-layout-content>
  </n-layout>
</template>

<script setup>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import {useRouter} from 'vue-router'
import {myListReview, myPaymentByReviewId} from '@/api/api'
import {ref} from "vue";

const router = useRouter()
const list = ref([])

function goDetail(item) {
  router.push('/settlement?id=' + item.id)
}

function goReport(item) {
  if (item.type === 2) {
    // router.push('Evaluation?type=1')
    // window.open('Evaluation?type=1', '_blank');
    return;
  }
  myPaymentByReviewId(item.id).then(res => {
    console.log('myPaymentByReviewId', res);
    if (res.data && res.data.paid === 1) {
      router.push('/report?id=' + item.id)
    } else {
      router.push('/settlement/payment?id=' + item.id)
    }
  })
}

myListReview({
  pageNum: 1,
  pageSize: 1000
}).then(res => {
  console.log('myListReview', res);
  list.value = res.rows;
})

function toPage(path) {
  router.push(path)
}
</script>

<style lang="scss" scoped>
.breadcrumb {
  width: 37.5rem;
  margin: 0 auto;
  margin-top: 0.75rem;
}

.container {
  max-width: 37.5rem;
  min-height: 21.0625rem;
  margin: 0 auto;
  margin-top: 0.5rem;
  background: #FFFFFF;
  box-shadow: 0px 0 0.125rem 0px rgba(0, 0, 0, 0.09);
  border-radius: 0.25rem;
  padding: 0.71875rem;

  .d-title {
    align-items: center;
    margin-bottom: 0.75rem;

    img {
      width: 0.75rem;
      height: 0.75rem;
      margin-right: 0.1875rem;
    }

    p {
      font-size: 0.53125rem;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: 600;
      color: #081D5D;
    }
  }

  .list {
    .item {
      .row,
      .row-2 {
        justify-content: space-between;
      }

      .row {
        align-items: center;

        .left {
          font-size: 0.5rem;
          font-family: SourceHanSansCN, SourceHanSansCN;
          color: #061B5C;
          cursor: pointer;
        }

        .right {
          font-size: 0.8125rem;
          font-family: Rubik, Rubik;
          font-weight: 600;
          color: #061B5C;

          span {
            font-size: 0.4375rem;
          }
        }
      }

      .btn {
        color: #fff !important;
        background-color: #d14538;
        padding: 5px 10px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        cursor: pointer;
      }

      .row-2 {
        .left,
        .right {
          font-size: 0.5rem;
          font-family: SourceHanSansCN, SourceHanSansCN;
          color: #999999;
        }

        .right {
          color: #FF6E00;
        }
      }

      background: #F9F9FC;
      border-radius: 0.1875rem;
      padding: 0.5rem 0.75rem;
      margin-bottom: 0.75rem;
    }
  }
}

.mt-25 {
  margin-top: 0.78125rem;
}

.mt-16 {
  margin-top: 0.5rem;
}
</style>
