<template>
  <n-layout>
    <n-layout-content>
      <div class="bg h-full">
        <Header/>
        <div style="min-height: calc(100% - 5.875rem); padding-bottom: 1.25rem;">
          <h1 class="title">江苏省高中生综评升学 AI自主测评</h1>
          <p class="intro text-center">
            本测评依据最新《江苏省关于普通高校综合评价招生改革试点工作的通知》和江苏省综合评价录取招生高校最新招生简章及录取结果。</p>
          <div class="btns">
            <a class="red" href="javascript:void(0)" @click="router.push('/Evaluation/intro')">
              <h2>测前须知</h2>
              <p>测前准备</p>
            </a>
            <a class="red" href="javascript:void(0)" @click="router.push('/Evaluation?type=1')">
              <h2>我要测评</h2>
              <p>获取综评升学报告</p>
            </a>
            <a class="red" href="http://www.jshzycedu.com/portal/course.html">
              <h2>课程学习</h2>
              <p>系统课程学习</p>
            </a>
            <a class="red" href="/Home#university-area">
              <h2>适配院校</h2>
              <p>奖项适配院校</p>
            </a>
          </div>
          <div class="breadcrumb">
            <n-breadcrumb>
              <n-breadcrumb-item @click="toHome" separator=">">首页</n-breadcrumb-item>
              <n-breadcrumb-item separator=">">测前须知</n-breadcrumb-item>
            </n-breadcrumb>
          </div>
          <div class="schools">
            <div class="d-title">
              江苏省综合评价升学测评 必读须知
            </div>
            <div class="intro ql-editor">
              <p>
                为全面贯彻落实《国务院关于深化考试招生制度改革的实施意见》、《国务院关于新时代推进普通高中育人方式改革的指导意见》及《江苏省关于普通高校综合评价招生改革试点工作的通知》精神，深化考试招生制度改革，省教育厅积极推进综合评价升学改革试点工作。</p>
              <p>
                综合评价招生模式旨：综合考量考生高考文化分、高校考核结果、高中学业水平考试成绩、综合素质评价以及高校自身培养的特色要求等多个维度，建立的一种多位一体的人才选拔综合评价体系。</p>
              <p>
                本平台的开发，结合综合评价升学阳光高考网和学校填报系统的申报要求，对填报内容进行梳理和分类；根据江苏省最新综合评价录取招生高校的简章要求，对简章进行智能化记录、拆解和存储；以往年的最新录取结果为数据库，建立了江苏综评升学评测模型；根据学生填报的数据，进行自动化比较，生成专属的评测报告。此报告由南京大学、南京师范大学等多名专家联合研发，依据历年录取数据和考评经验，建立独特的“赋分制”评价规则，具有原创性和科学性。本报告旨在帮助学生科学规划，查露补缺；辅助精准施策，助力圆梦理想高校。</p>
              <p>测评前请仔细阅读以下内容：</p>
              <p class="bold-txt">一、测评预计时间 15分钟左右</p>
              <p class="bold-txt">二、测评材料准备：</p>
              <p>1. 期末考试、模考的成绩及排名</p>
              <p>2. 各类竞赛活动奖项证书</p>
              <p>3. 个人陈述/自荐信</p>
              <p>4. 推荐信材料</p>
              <p>5. 研究性学习成果证书</p>
              <p>6. 通用技术作品证书</p>
              <p>7. 社会实践证明材料/证书</p>
              <p>8. 志愿服务证明材料/证书</p>
              <p>9. 艺术素养活动/体育素养活动证书</p>
              <p>10. 体测报告</p>
              <p>11. 考生基本信息及成绩信息等材料</p>
              <p class="bold-txt">三、测评步骤</p>
              <p>1. 点击“注册/登录”拥有自己的账号。</p>
              <p>2. 点击任何一所高校或者点击“我要测评”进入测评界面，按照提示完成测评。题目主要 包括基本信息、成绩信息、获奖情况等。</p>
              <p>3. 点击完成，将在“个人中心”自动生成综合评价升学测评报告。</p>
              <p>4. 进入“个人中心”，点击“我的综评”，再点击“综评升学报告”，最后支付即可获得属于您的专属测评报告。</p>
              <p>5. 依据综合评价测评报告，主动联系规划师。</p>
              <p class="bold-txt">四、测评优势：</p>
              <p>1.
                全面评估：江苏省高中生综合评价升学AI自主测评围绕考生学业水平成绩、综合素质评价、考生意愿等多个维度进行全面评估，让考生更清晰地了解自己的优势与不足。</p>
              <p>2. 科学选校：依据综合评价报告，考生可以更加科学地选择适合自己的院校乃至专业，提高录取成功率。</p>
              <p>3.
                智能化推荐：根据江苏省最新综合评价录取招生高校的简章要求，对简章进行智能化记录、拆解和存储。智能推荐冲、稳的院校，为考生提供更多升入名校的机会。</p>
              <p>4. 实时反馈：测评过程中，考生可以实时查看自己的测评进度和成绩，了解自己在各部分的表现。</p>
              <p>5. 个性化指导：测评结束后，考生可根据综合评价报告，针对自己不足之处进行有针对性的提升，为接下来的升学准备做好铺垫。</p>
              <p class="bold-txt">五、注意事项：</p>
              <p>1. 测评前请务必准备好相关材料，以确保测评的准确性。</p>
              <p>2. 测评过程中请仔细核对材料信息，特别是奖项证书的发证单位及奖项名称。</p>
              <p>3. 测评结束后，请认真查看综合评价升学测评报告，并根据报告进行相应的提升和调整。</p>
              <p>4. 在选校过程中，考生应综合考虑自身兴趣、特长和发展前景，切勿盲目追求名校。</p>
              <p>5.
                测评结果仅供参考，我们建议您经常与自己的规划师沟通和交流，根据自己的兴趣和爱好，科学规划，精准施策，早做准备。相信通过您的坚持和努力，定会圆梦理想名校。</p>
            </div>
            <div class="intro-check" v-if="notice">
              <n-checkbox v-model:checked="introCheck" @update:checked="introCheckChange">
                已完成“测前须知”
              </n-checkbox>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    </n-layout-content>
  </n-layout>
</template>

<script setup>
import {onMounted, ref} from 'vue'
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import {useRouter, useRoute} from 'vue-router'
import {dictType, universityDetail} from '@/api/api'
import {useDialog} from "naive-ui";

const router = useRouter()
const route = useRoute()
const curName = ref('')
const curLabel = ref('')
const curData = ref({})
const cid = ref(route.query.cid)
const introCheck = ref(false);

const dialog = useDialog()
const url = ref(null);
const notice = ref(false);

onMounted(() => {
  if (route.query.url) {
    url.value = decodeURIComponent(route.query.url)
  }
  if (route.query.notice) {
    notice.value = true;
    //弹窗提示
    dialog.info({
      title: "测前须知",
      content: "请仔细阅读“测前须知”，做好测前准备。阅读完成后，请勾选：已完成",
    })
  }
})

function introCheckChange(value) {
  if (value) {
    localStorage.setItem('introCheck', "true")
    if (url.value) {
      window.open(url.value, "_self");
    }
  }
}

function toHome() {
  router.push('/home')
}

function toSchoolList(id) {
  router.push('/school/' + id)
}

</script>

<style lang="scss">
.ql-editor {
  text-indent: 1rem;
  font-size: 0.5rem !important;

  img {
    margin: 0 auto;
  }
}

.intro-check {
  text-align: center;

  .n-checkbox__label {
    color: #d14538 !important;
  }
}

</style>

<style lang="scss" scoped>

.breadcrumb {
  width: 37.5rem;
  margin: 0 auto;
  margin-top: 0.75rem;
}

.bold-txt {
  font-weight: bold;
  margin: 0.2rem auto;
}

.title {
  font-size: 1.25rem;
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: bold;
  color: #061B5C;
  line-height: 1.8125rem;
  letter-spacing: 0.03125rem;
  text-align: center;
  margin-top: 1.96875rem;
  margin-bottom: 0.625rem;
}

.intro {
  font-size: 0.375rem;
  font-family: SourceHanSansCN, SourceHanSansCN;
  color: #666666;
  max-width: 29.84375rem;
  margin: 0 auto;
  margin-bottom: 1.0625rem;
}

.btns {
  display: flex;
  justify-content: center;

  a {
    width: 5.25rem;
    height: 1.8125rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 0.0625rem;

    h2 {
      font-size: 0.5625rem;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: bold;
      color: #FFFFFF;
    }

    p {
      font-size: 0.375rem;
      font-family: SourceHanSansCN, SourceHanSansCN;
      color: #FFFFFF;
    }
  }

  .red {
    background: linear-gradient(270deg, #d14538 0%, #F37457 100%);
    margin-right: 0.78125rem;
  }

  .blue {
    background: linear-gradient(270deg, #061B5C 0%, #33457B 100%);
  }
}

.schools {
  max-width: 37.5rem;
  min-height: 21.0625rem;
  margin: 0 auto;
  margin-top: 0.5rem;
  padding: 0.71875rem;
  background: #FFFFFF;
  box-shadow: 0px 0 0.125rem 0px rgba(0, 0, 0, 0.09);
  border-radius: 0.25rem;
  font-size: 0.4375rem;
  font-family: SourceHanSansCN, SourceHanSansCN;

  .d-title {
    font-size: 1.25rem;
    font-family: SourceHanSansCN, SourceHanSansCN;
    font-weight: bold;
    color: #061B5C;
    line-height: 1.8125rem;
    letter-spacing: 0.03125rem;
    text-align: center;
  }

  .intro {
    margin-top: 0.625rem;
  }
}

@media screen and (max-width: 961px) {
  .breadcrumb {
    display: none;
  }

  .schools {

    width: calc(100% - 30px);
    margin: 0 auto;
    margin-top: 15px;
  }
}

@media screen and (max-width: 781px) {
  .schools {
    width: calc(100% - 30px);
    margin: 0 auto;
    margin-top: 15px;

    .list {
      .item {
        width: calc(50% - 1rem);
      }
    }
  }
}

@media screen and (max-width: 531px) {
  .schools {
    width: calc(100% - 30px);
    margin: 0 auto;
    margin-top: 15px;

    .list {
      .item {
        width: 100%;
      }
    }
  }
}

.mt-25 {
  margin-top: 0.78125rem;
}

.mt-16 {
  margin-top: 0.5rem;
}
</style>
