<template>
  <n-layout>
    <n-layout-content>
      <div class="bg h-full">
        <Header/>
        <div style="min-height: calc(100% - 5.875rem); padding-bottom: 1.25rem;">
          <h1 class="title">江苏省高中生综评升学 AI自主测评</h1>
          <p class="intro text-center">本测评依据最新《江苏省关于普通高校综合评价招生改革试点工作的通知》和江苏省综合评价录取招生高校最新招生简章及录取结果。</p>
          <div class="btns">
            <a class="red" href="javascript:void(0)" @click="router.push('/Evaluation/intro')">
              <h2>测前须知</h2>
              <p>测前准备</p>
            </a>
            <a class="red" href="javascript:void(0)" @click="router.push('/Evaluation?type=1')">
              <h2>我要测评</h2>
              <p>获取综评升学报告</p>
            </a>
            <a class="red" href="http://www.jshzycedu.com/portal/course.html">
              <h2>课程学习</h2>
              <p>系统课程学习</p>
            </a>
            <a class="red" href="/Home#university-area">
              <h2>适配院校</h2>
              <p>奖项适配院校</p>
            </a>
          </div>
          <div class="breadcrumb">
            <n-breadcrumb>
              <n-breadcrumb-item @click="toHome" separator=">">首页</n-breadcrumb-item>
              <n-breadcrumb-item>{{ lvName }}</n-breadcrumb-item>
            </n-breadcrumb>
          </div>
          <div class="schools">
            <div class="d-title flex">
              <img src="../../assets/images/directory.png">
              <p>江苏省综评院校名录（{{ lvName }}）</p>
            </div>
            <div class="list flex">
              <div class="item" v-for="item in schools" @click="toPageParams('/detail', item.levelId, item.id)">
                <div class="top-area">
                  <img class="top-area-img" :src="getImgUrl(item.banner)">
                  <div class="head flex">
                    <div class="avatar">
                      <img :src="getImgUrl(item.logo)">
                    </div>
                    <p>{{ item.name }}</p>
                  </div>
                </div>
                <div class="bottom-area">
                  <p>{{ item.intro }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    </n-layout-content>
  </n-layout>
</template>

<script setup>
import {ref, onMounted} from 'vue'
import {dictType, universityList} from '@/api/api'
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import {useRouter, useRoute} from 'vue-router'

const router = useRouter()
const route = useRoute()
const universityLevel = ref([])
const lvName = ref('')
const schools = ref([])

function getImgUrl(path) {
  return process.env.VUE_APP_BASE_API + path;
}

const fetchData = async () => {
  try {
    const result = await dictType('/hz_university_level')
    const list = await universityList({
      pageNum: 1,
      pageSize: 1000
    })
    universityLevel.value = result.data
    universityLevel.value.forEach((item, i) => {
      if (item.dictValue == route.params.id) {
        lvName.value = item.dictLabel
      }
    })
    list.rows.forEach((item, i) => {
      if (item.levelId == route.params.id) {
        schools.value.push(item)
      }
    });
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

function toHome() {
  router.push('/home')
}

function toPageParams(path, cid, id) {
  router.push({
    path: path, query: {
      cid,
      id
    }
  })
}

onMounted(() => {
  fetchData()
})
</script>

<style lang="scss" scoped>
.breadcrumb {
  width: 37.5rem;
  margin: 0 auto;
  margin-top: 0.75rem;
}

.title {
  font-size: 1.25rem;
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: bold;
  color: #061B5C;
  line-height: 1.8125rem;
  letter-spacing: 0.03125rem;
  text-align: center;
  margin-top: 1.96875rem;
  margin-bottom: 0.625rem;
}

.intro {
  font-size: 0.375rem;
  font-family: SourceHanSansCN, SourceHanSansCN;
  color: #666666;
  line-height: 0.5625rem;
  text-align: center;
  max-width: 29.84375rem;
  margin: 0 auto;
  margin-bottom: 1.0625rem;
}

.btns {
  display: flex;
  justify-content: center;

  a {
    width: 5.25rem;
    height: 1.8125rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 0.0625rem;

    h2 {
      font-size: 0.5625rem;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: bold;
      color: #FFFFFF;
    }

    p {
      font-size: 0.375rem;
      font-family: SourceHanSansCN, SourceHanSansCN;
      color: #FFFFFF;
    }
  }

  .red {
    background: linear-gradient(270deg, #d14538 0%, #F37457 100%);
    margin-right: 0.78125rem;
  }

  .blue {
    background: linear-gradient(270deg, #061B5C 0%, #33457B 100%);
  }
}

.schools {
  max-width: 37.5rem;
  min-height: 21.0625rem;
  margin: 0 auto;
  margin-top: 0.5rem;
  padding: 0.71875rem;
  background: #FFFFFF;
  box-shadow: 0px 0 0.125rem 0px rgba(0, 0, 0, 0.09);
  border-radius: 0.25rem;

  .d-title {
    align-items: center;
    margin-bottom: 0.75rem;

    img {
      width: 0.75rem;
      height: 0.75rem;
      margin-right: 0.1875rem;
    }

    p {
      font-size: 0.53125rem;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: 600;
      color: #d14538;
    }
  }

  .list {
    flex-wrap: wrap;
    justify-content: space-between;

    &::after {
      content: '';
      width: calc(33.33% - 1rem);
    }

    .item {
      width: calc(33.33% - 1rem);
      border-radius: 0.125rem;
      margin-bottom: 1.125rem;
      overflow: hidden;
      cursor: pointer;
    }

    .top-area {
      position: relative;
      width: 100%;
      height: 4.875rem;
      //background: url(https://img0.baidu.com/it/u=2332300470,3086281722&fm=253&fmt=auto&app=138&f=JPEG?w=750&h=500) no-repeat;
      background-size: cover;
      background-position: center;
      padding: 0.375rem;
      box-sizing: border-box;

      .top-area-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(6, 27, 92, 0.2);

      }

      .head {
        position: relative;
        align-items: center;
        z-index: 99;

        .avatar {
          width: 1.125rem;
          height: 1.125rem;
          background: #FFFFFF;
          border-radius: 0.125rem;
          border: 0.03125rem solid #D4D9DF;
          margin-right: 0.375rem;
        }

        p {
          font-size: 0.4375rem;
          font-family: SourceHanSansCN, SourceHanSansCN;
          color: #fff;
        }
      }
    }

    .bottom-area {
      width: 100%;
      background: linear-gradient(360deg, #F0F5FF 0%, #F2F6FF 100%);
      font-size: 12px;
      font-family: SourceHanSansCN, SourceHanSansCN;
      color: #666666;
      padding: 0.375rem;
      box-sizing: border-box;

      p {
        display: -webkit-box;
        -webkit-line-clamp: 5; /* 设置显示的行数 */
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      a {
        color: #d14538;
      }
    }
  }
}

@media screen and (max-width: 961px) {
  .breadcrumb {
    display: none;
  }

  .schools {

    width: calc(100% - 30px);
    margin: 0 auto;
    margin-top: 15px;
  }
}

@media screen and (max-width: 781px) {
  .schools {
    width: calc(100% - 30px);
    margin: 0 auto;
    margin-top: 15px;

    .list {
      .item {
        width: calc(50% - 1rem);
      }
    }
  }
}

@media screen and (max-width: 531px) {
  .schools {
    width: calc(100% - 30px);
    margin: 0 auto;
    margin-top: 15px;

    .list {
      .item {
        width: 100%;
      }
    }
  }
}

.mt-25 {
  margin-top: 0.78125rem;
}

.mt-16 {
  margin-top: 0.5rem;
}
</style>
