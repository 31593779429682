<template>
  <n-layout>
    <n-layout-content>
      <div class="bg h-full">
        <Header/>
        <div style="min-height: calc(100% - 5.875rem); padding-bottom: 1.25rem;">
          <h1 class="title">江苏省高中生综评升学 AI自主测评</h1>
          <p class="intro text-center">
            本测评依据最新《江苏省关于普通高校综合评价招生改革试点工作的通知》和江苏省综合评价录取招生高校最新招生简章及录取结果。</p>
          <div class="btns">
            <a class="red" href="javascript:void(0)" @click="router.push('/Evaluation/intro')">
              <h2>测前须知</h2>
              <p>测前准备</p>
            </a>
            <a class="red" href="javascript:void(0)" @click="reloadEvaluation">
              <h2>我要测评</h2>
              <p>获取综评升学报告</p>
            </a>
            <a class="red" href="http://www.jshzycedu.com/portal/course.html">
              <h2>课程学习</h2>
              <p>系统课程学习</p>
            </a>
            <a class="red" href="/Home#university-area">
              <h2>适配院校</h2>
              <p>奖项适配院校</p>
            </a>
          </div>
          <div class="breadcrumb">
            <n-breadcrumb>
              <n-breadcrumb-item @click="toPage('/home')" separator=">">首页</n-breadcrumb-item>
              <n-breadcrumb-item @click="toSchoolList(cid)" separator=">" v-if="curLabel">{{
                  curLabel
                }}
              </n-breadcrumb-item>
              <n-breadcrumb-item v-if="curName">{{ curName }}</n-breadcrumb-item>
            </n-breadcrumb>
          </div>
          <div class="container">
            <div class="d-title flex">
              <img src="../../assets/images/directory.png">
              <p>{{ curName }}</p>
            </div>
            <div class="check-area" v-show="page == 0">
              <div class="row flex">
                <div class="check-opt flex hl-f">
                  基本信息（“*”为必填项）
                </div>
              </div>
              <n-form :model="form" ref="formRefBase" label-placement="left">
                <div class="row flex">
                  <div class="check-opt flex">
                    <n-form-item label="学生姓名" path="baseName" :rule="{
                        required: true,
                        pattern: /^[\u4e00-\u9fa5]{2,4}$/,
                        message: '请输入姓名',
                        trigger: ['input', 'blur']
                      }">
                      <n-input v-model:value="form.baseName" placeholder="请输入学生姓名" :style="{ width: '200px' }"/>
                    </n-form-item>
                  </div>
                </div>
                <div class="row flex">
                  <div class="check-opt flex">
                    <n-form-item label="性别" path="baseGender" :rule="{
                        required: true,
                        message: '请输入性别',
                        trigger: 'blur'
                      }">
                      <n-radio-group v-model:value="form.baseGender">
                        <n-space>
                          <n-radio key="男" value="男">男</n-radio>
                          <n-radio key="女" value="女">女</n-radio>
                        </n-space>
                      </n-radio-group>
                    </n-form-item>
                  </div>
                </div>
                <div class="row flex">
                  <div class="check-opt flex">
                    <n-form-item label="预估分数" path="baseScore" :rule="{
                        required: true,
                        pattern: /^[1-9][0-9]*([\.][0-9]{1,2})?$/,
                        message: '请输入预估分数',
                        trigger: ['input', 'blur']
                      }">
                      <n-input v-model:value="form.baseScore" placeholder="请输入预估分数" :style="{ width: '200px' }"/>
                    </n-form-item>
                  </div>
                </div>
                <div class="row flex">
                  <div class="check-opt flex">
                    <n-form-item label="意向高校" path="baseUniversityId" :rule="{
                        required: true,
                        message: '请输入意向高校',
                        type:'array',
                        trigger: 'blur'
                      }">
                      <n-select v-model:value="form.baseUniversityId" placeholder="请选择意向高校" multiple
                                :style="{ width: '200px' }" :options="selectOptions1"
                                @change="(val)=>baseSelectControlChange(val,selectOptions1,5)"/>
                    </n-form-item>
                  </div>
                </div>
                <div class="row flex">
                  <div class="check-opt flex">
                    <n-form-item label="意向专业" path="baseMajor" :rule="{
                        required: true,
                        message: '请输入意向专业',
                        type:'array',
                        trigger: 'blur'
                      }">
                      <n-select v-model:value="form.baseMajor" placeholder="请选择意向专业" multiple
                                :style="{ width: '200px' }" :options="selectOptions2"
                                @change="(val)=>baseSelectControlChange(val,selectOptions2,3)"/>
                    </n-form-item>
                  </div>
                </div>
                <div class="row flex">
                  <div class="check-opt flex">
                    <n-form-item label="年级" path="baseGrades" :rule="{
                        required: true,
                        message: '请输入年级',
                        trigger: 'blur'
                      }">
                      <n-radio-group v-model:value="form.baseGrades">
                        <n-space>
                          <n-radio v-for="item in grades" :key="item.dictValue" :value="item.dictValue">
                            {{ item.dictLabel }}
                          </n-radio>
                        </n-space>
                      </n-radio-group>
                    </n-form-item>
                  </div>
                </div>
                <div class="row flex">
                  <div class="check-opt flex">
                    <n-form-item label="身体素质" path="basePhysicalLevel" :rule="{
                        required: true,
                        message: '请输入身体素质',
                        trigger: 'blur'
                      }">
                      <n-radio-group v-model:value="form.basePhysicalLevel">
                        <n-space>
                          <n-radio v-for="item in physical" :key="item.dictValue" :value="item.dictValue">
                            {{ item.dictLabel }}
                          </n-radio>
                        </n-space>
                      </n-radio-group>
                    </n-form-item>
                  </div>
                </div>
                <div class="row flex">
                  <div class="check-opt flex">
                    <n-form-item label="主选科目" path="basePrimarySubject" :rule="{
                        required: true,
                        message: '请输入主选科目',
                        trigger: 'blur'
                      }">
                      <n-radio-group v-model:value="form.basePrimarySubject">
                        <n-space>
                          <n-radio v-for="item in primary" :key="item.dictValue" :value="item.dictValue">
                            {{ item.dictLabel }}
                          </n-radio>
                        </n-space>
                      </n-radio-group>
                    </n-form-item>
                  </div>
                </div>
                <div class="row flex">
                  <div class="check-opt flex">
                    <n-form-item label="选修科目" path="baseElectiveSubject" :rule="{
                        required: true,
                        message: '请输入选修科目',
                        type:'array',
                        len: 2,
                        trigger: 'blur'
                      }">
                      <n-checkbox-group v-model:value="form.baseElectiveSubject" max="2">
                        <n-space>
                          <n-checkbox v-for="item in elective" :key="item.dictValue" :value="item.dictValue">
                            {{ item.dictLabel }}
                          </n-checkbox>
                        </n-space>
                      </n-checkbox-group>
                    </n-form-item>
                  </div>
                </div>
                <div class="row flex">
                  <div class="check-opt flex">
                    <n-form-item label="英语能力" path="baseEnglishLevel" :rule="{
                        required: true,
                        message: '请输入英语能力',
                        trigger: 'blur'
                      }">
                      <n-radio-group v-model:value="form.baseEnglishLevel">
                        <n-space>
                          <n-radio v-for="item in english" :key="item.dictValue" :value="item.dictValue">
                            {{ item.dictLabel }}
                          </n-radio>
                        </n-space>
                      </n-radio-group>
                    </n-form-item>
                  </div>
                </div>
                <div class="row flex">
                  <div class="check-opt flex hl-f">
                    成绩信息（“*”为必填项）
                  </div>
                </div>
                <div class="row flex">
                  <div class="check-opt flex">
                    <n-form-item label="高中信息" path="gradeSchool" :rule="{
                        required: true,
                        message: '请输入高中信息',
                        type:'number',
                        trigger: 'blur'
                      }">
                      <n-grid cols="12">
                        <n-gi span="12">
                          <n-cascader
                              :style="{ width: '200px' }"
                              v-model:value="form.gradeSchoolArea"
                              :options="areaOptions"
                              value-field="id"
                              label-field="name"
                              check-strategy="child"
                              @update:value="areaChange"
                          />
                        </n-gi>
                        <n-gi span="12">
                          <n-select v-model:value="form.gradeSchool" placeholder="请选择高中信息"
                                    :style="{ width: '200px' }"
                                    :options="selectOptions3" @change="schoolChange"/>
                        </n-gi>
                        <n-gi span="12">
                          <div class="hl-f-t" @click="noSchool()">
                            没有对应高中？点击联系我们
                          </div>
                        </n-gi>
                      </n-grid>
                    </n-form-item>
                  </div>
                </div>
                <div class="row flex">
                  <div class="check-opt flex">
                    <n-form-item label="年级排名" path="gradeScoreLevel" :rule="{
                        required: true,
                        message: '请输入年级排名',
                        trigger: 'blur'
                      }">
                      <n-radio-group v-model:value="form.gradeScoreLevel">
                        <n-space>
                          <n-radio v-for="item in score" :key="item.dictValue" :value="item.dictValue">
                            {{ item.dictLabel }}
                          </n-radio>
                        </n-space>
                      </n-radio-group>
                    </n-form-item>
                  </div>
                </div>
              </n-form>
            </div>
            <div>
              <n-form :model="awardTypes" ref="formRefs" label-placement="left" v-for="(type,typeIndex) in awardTypes.types">
                <div class="check-area" v-show="typeIndex === page-1">
                  <div class="row flex">
                    <div class="check-opt flex  hl-f" style="color: #e3352d;font-weight: bold">
                      {{ type.name }} (*为必填项，所填信息与证书或奖状一致)
                    </div>
                  </div>

                  <div v-for="(awardItem,index) in type.awards" class="award-item row">
                    <n-form-item label="奖项类别" :key="typeIndex+index" :path="`types[${typeIndex}].awards[${index}].raceCategory`"
                                 v-if="!type.inputName"
                                 :rule="{
                        required: true,
                        message: '请输入奖项类别',
                        trigger: 'blur'
                      }">
                      <n-radio-group v-model:value="awardItem.raceCategory">
                        <n-space>
                          <n-radio v-for="item in getRaceCategory(type.id)" :key="item.dictValue"
                                   :value="item.dictValue" @change="raceCategoryChange(awardItem,item)">
                            {{ item.dictLabel }}
                          </n-radio>
                        </n-space>
                      </n-radio-group>
                    </n-form-item>
                    <n-form-item :label="type.id==='8'?'创新潜质名称':'奖项名称'" :key="typeIndex+index"
                                 :path="`types[${typeIndex}].awards[${index}].raceName`"
                                 v-else
                                 :rule="type.id!=='8'?{
                        required: true,
                        pattern: /^(?!(\s+$))/,
                        message: '请输入名称',
                        trigger: 'blur'
                      }:{}">
                      <n-input v-model:value="awardItem.raceName" :placeholder="type.id==='8'?'教师推荐信、医生推荐信等':'请输入名称'"
                               :style="{ width: '200px' }"/>
                    </n-form-item>

                    <n-form-item label="组织单位" :key="typeIndex+index" :path="`types[${typeIndex}].awards[${index}].raceOrg`"
                                 v-if="awardItem.raceOrgOptions&&awardItem.raceOrgOptions.length>0"
                                 :rule="{
                        required: true,
                        type:'array',
                        message: '请输入组织单位',
                        trigger: 'blur'
                      }">
                      <n-checkbox-group v-model:value="awardItem.raceOrg">
                        <n-space item-style="display: flex;">
                          <n-checkbox v-for="option in awardItem.raceOrgOptions" :value="option" :label="option"/>
                        </n-space>
                      </n-checkbox-group>
                    </n-form-item>

                    <n-form-item label="组织单位" :key="typeIndex+index" :path="`types[${typeIndex}].awards[${index}].raceOrg`"
                                 v-if="page===7&&typeIndex===6"
                                 :rule="{
                        required: true,
                        pattern: /^(?!(\s+$))/,
                        message: '请输入组织单位',
                        trigger: 'blur'
                      }">
                      <n-input v-model:value="awardItem.raceOrg" placeholder="组织单位"
                               :style="{ width: '200px' }"/>
                    </n-form-item>

                    <n-form-item label="优势类型" :key="typeIndex+index" :path="`types[${typeIndex}].awards[${index}].advantageType`"
                                 v-if="page===7&&typeIndex===6"
                                 :rule="{
                        required: true,
                        message: '请输入优势类型',
                        trigger: 'blur'
                      }">
                      <n-radio-group v-model:value="awardItem.advantageType">
                        <n-space>
                          <n-radio v-for="item in advantage" :key="item.dictValue" :value="item.dictValue">
                            {{ item.dictLabel }}
                          </n-radio>
                        </n-space>
                      </n-radio-group>
                    </n-form-item>

                    <n-form-item label="奖项级别" :key="typeIndex+index" :path="`types[${typeIndex}].awards[${index}].raceLevel`"
                                 v-if="awardItem.advantageType&&awardItem.advantageType<=3&&type.id!=='8'"
                                 :rule="{
                        required: true,
                        message: '请输入奖项级别',
                        trigger: 'blur'
                      }">
                      <n-radio-group v-model:value="awardItem.raceLevel">
                        <n-space>
                          <n-radio v-for="item in award2" :key="item.dictValue" :value="item.dictValue">
                            {{ item.dictLabel }}
                          </n-radio>
                        </n-space>
                      </n-radio-group>
                    </n-form-item>

                    <n-form-item label="奖项级别" :key="typeIndex+index" :path="`types[${typeIndex}].awards[${index}].raceLevel`"
                                 v-if="awardItem.advantageType&&awardItem.advantageType>3&&type.id!=='8'"
                                 :rule="{
                        required: true,
                        message: '请输入奖项级别',
                        trigger: 'blur'
                      }">
                      <n-radio-group v-model:value="awardItem.raceLevel">
                        <n-space>
                          <n-radio v-for="item in award3" :key="item.dictValue" :value="item.dictValue">
                            {{ item.dictLabel }}
                          </n-radio>
                        </n-space>
                      </n-radio-group>
                    </n-form-item>

                    <n-form-item label="奖项级别" :key="typeIndex+index" :path="`types[${typeIndex}].awards[${index}].raceLevel`"
                                 v-if="page!==7&&type.id!=='8'"
                                 :rule="{
                        required: true,
                        message: '请输入奖项级别',
                        trigger: 'blur'
                      }">
                      <n-radio-group v-model:value="awardItem.raceLevel">
                        <n-space>
                          <n-radio v-for="item in getRaceLevel(type)" :key="item.dictValue" :value="item.dictValue">
                            {{ item.dictLabel }}
                          </n-radio>
                        </n-space>
                      </n-radio-group>
                    </n-form-item>

                    <n-form-item label="获奖等级" :key="typeIndex+index" :path="`types[${typeIndex}].awards[${index}].raceRank`"
                                 v-if="type.id!=='7'&&type.id!=='8'&&(type.id!=='9'||type.id==='9'&&awardItem.advantageType<=2)"
                                 :rule="{
                        required: true,
                        message: '请输入获奖等级',
                        trigger: 'blur'
                      }">
                      <n-radio-group v-model:value="awardItem.raceRank">
                        <n-space>
                          <n-radio v-for="item in rank" :key="item.dictValue" :value="item.dictValue">
                            {{ item.dictLabel }}
                          </n-radio>
                        </n-space>
                      </n-radio-group>
                    </n-form-item>

                    <n-form-item label="证书编号" :key="typeIndex+index" :path="`types[${typeIndex}].awards[${index}].raceNum`"
                                 v-if="type.id!=='7'&&type.id!=='8'&&type.id!=='9'">
                      <n-input v-model:value="awardItem.raceNum" placeholder="请输入证书编号"
                               :style="{ width: '200px',marginLeft:'0.3rem' }"/>
                    </n-form-item>

                    <div class="row flex" v-if="page === 6">
                      <div class="label">
                        {{ type.id === '8' ? '创新潜质材料附件' : '证书附件' }}
                      </div>
                      <div class="check-opt flex">
                        <n-upload :action="uploadAction" :max="1" @finish="(file)=>fileUploadFinish(file,awardItem)"
                                  :multiple="false" :headers="uploadHeaders">
                          <n-button>上传文件</n-button>
                        </n-upload>
                      </div>
                    </div>
                    <n-form-item :label="type.id==='8'?'创新潜质证明日期':'发证日期'" :key="typeIndex+index"
                                 :path="`types[${typeIndex}].awards[${index}].raceDate`"
                                 :rule="{
                        required: type.id!=='8',
                        type:'number',
                        message: '请选择发证日期',
                        trigger: 'blur'
                      }">
                      <div class="check-opt flex">
                        <n-date-picker v-model:value="awardItem.raceDate" placeholder="请填写高中期间证书" type="date"
                                       :is-date-disabled="(ts)=>{return ts > Date.now();}"/>
                      </div>

                    </n-form-item>

                    <div class="del-btn" @click="delAward(type,index)">无此奖请删除</div>

                  </div>
                  <div class="btn" @click="addAward(type)">添加新增奖项</div>
                  <div class="btn-tips">有奖可 ”新增奖项“ 无奖 ”下一页“</div>
                </div>
              </n-form>
            </div>
            <div class="foot flex">
              <div class="btn" @click="back" v-if="page>0&&page>startPage">
                上一页
              </div>
              <div class="btn" @click="next" v-if="page!==7&&page!==endPage">
                下一页
              </div>
              <div class="btn" @click="save" v-if="page===7||page===endPage">
                完成
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    </n-layout-content>
  </n-layout>
</template>

<script setup>
import {ref, onMounted, nextTick} from 'vue'
import {
  dictType,
  universityDetail,
  universityList,
  schoolList,
  addReview,
  addReviewAward,
  areaList
} from '@/api/api'
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import {useRouter, useRoute} from 'vue-router'
import {getToken} from "@/util/auth";
import {useDialog, useMessage} from "naive-ui";

const message = useMessage();

const router = useRouter()
const route = useRoute()
const page = ref(0)
const startPage = ref(0)
const endPage = ref(0)
const cid = ref(route.query.cid)
const curName = ref('')
const curLabel = ref('')
const curData = ref({})

const grades = ref([])
const physical = ref([])
const primary = ref([])
const elective = ref([])
const english = ref([])
const school = ref([])
const score = ref([])
const competition = ref([])
const award = ref([])
const rank = ref([])
const award2 = ref([])
const award3 = ref([])
const advantage = ref([])
const selectOptions1 = ref([])
const selectOptions2 = ref([])
const selectOptions3 = ref([])
const selectOptions4 = ref([])
const areaOptions = ref([])
const uploadAction = ref("")
const uploadHeaders = ref({
  Authorization: "Bearer " + getToken()
})

const formRefs = ref([])
const formRefBase = ref(null)

const type = ref('')
const form = ref({
  id: null,
  type: 1,
  baseName: null,
  baseGender: null,
  baseScore: null,
  baseUniversityId: [],
  baseMajor: null,
  baseGrades: null,
  basePhysicalLevel: null,
  basePrimarySubject: null,
  baseElectiveSubject: null,
  baseEnglishLevel: null,
  gradeSchoolArea: [],
  gradeSchool: null,
  gradeSchoolLevel: null,
  gradeScoreLevel: null
})
const awardTypes = ref(
    {
      types: [
        {name: '五大学科竞赛奖项', id: '3', inputName: false, awards: []},
        {name: '全国竞赛活动奖项', id: '4', inputName: false, awards: []},
        {name: '全省竞赛活动奖项', id: '5', inputName: false, awards: []},
        {name: '其他竞赛活动奖项', id: '6', inputName: false, awards: []},
        {name: '荣誉表彰奖项', id: '7', inputName: false, awards: []},
        {name: '创新潜质项', id: '8', inputName: true, awards: []},
        {name: '综评档案优势得分项', id: '9', inputName: true, awards: []},
      ]
    }
)
const dialog = useDialog()

onMounted(() => {
  //判断是否需要阅读须知
  let introCheck = localStorage.getItem('introCheck');
  if (!introCheck) {
    router.push('/Evaluation/intro?notice=true&url=' + encodeURIComponent(window.location.href));
  }
  uploadAction.value = process.env.VUE_APP_BASE_API + "/api/common/upload";
  if (route.query.startPage) {
    page.value = parseInt(route.query.startPage);
    startPage.value = parseInt(route.query.startPage);
  }
  if (route.query.endPage) {
    endPage.value = parseInt(route.query.endPage);
  }
  let token = getToken();
  if (!token) {
    router.push('/login')
  }
  if (route.query.type) {
    form.value.type = route.query.type;
  }
  if (form.value.type === "1") {
    page.value = 0;
    startPage.value = 0;
    endPage.value = 7;
  }
  if (page.value !== 0) {
    console.log('onMounted addAward...');
    addAward(awardTypes.value.types[page.value - 1]);
  }
})

function reloadEvaluation() {
  router.push('/Evaluation?type=1');
  location.href = "/Evaluation?type=1";
}

function baseSelectControlChange(val, options, max) {
  if (val.length >= max) {
    options.map(i => {
      if (val.indexOf(i.value) < 0) {
        i.disabled = true;
      } else {
        i.disabled = false;
      }
    })
  } else {
    options.map(i => {
      i.disabled = false;
    })
  }
}

function fileUploadFinish(file, awardItem) {
  let res = JSON.parse(file.event.target.response);
  console.log('fileUploadFinish', file, res, awardItem);
  awardItem.file = res.fileName;
}

function getRaceLevel(type) {
  console.log('getRaceLevel', type, award.value);
  let awardLevel = [];
  for (let i of award.value) {
    if (type.id === '5' && i.dictLabel.indexOf('国家级') > -1) {
      continue;
    }
    awardLevel.push(i);
  }
  return awardLevel;
}

function raceCategoryChange(item, category) {
  console.log('raceCategoryChange', item, category);
  item.raceOrg = null;
  if (category.remark) {
    item.raceOrgOptions = category.remark.split(',');
  }
}

function getRaceCategory(reviewType) {
  let categoryList = competition.value.filter(i => {
    return i.category === reviewType
  });
  console.log('categoryList', categoryList);
  return categoryList;
}

function isMobile() {
  let flag = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  return flag;
}

function customer() {
  console.log('customer');
  if (isMobile()) {
    window.open("http://120.46.51.128/webchatbot-new/#/h5chat?sysNum=1706004011906&sourceId=8362&lang=zh_CN");
  } else {
    window.open("http://120.46.51.128/webchatbot-pc/#/chatNew?sysNum=1706004011906&sourceId=8361&lang=zh_CN");
  }
}

function areaChange(item, option, pathValues) {
  let areaIds = [];
  pathValues.map(i => {
    areaIds.push(i.id);
  })
  console.log('areaChange', item, option, pathValues, areaIds);
  getSchoolList("100000," + areaIds.join(','));
}

function schoolChange(item, value) {
  console.log('schoolChange', item, value);
  form.value.gradeSchoolLevel = value.obj.level;
}

function addAward(item) {
  item.awards.push({
    raceCategory: null,
    raceName: null,
    raceLevel: null,
    raceRank: null,
    raceNum: null,
    raceDate: null,
    raceOrg: null,
    raceOrgOptions: [],
    advantageType: null,
    file: null
  });
}

function delAward(item, index) {
  item.awards.splice(index, 1);
}

function jsonTree(data, config) {
  let id = config.id || "id",
      parentId = config.parentId || "parentId",
      children = config.children || "children";

  let idMap = [],
      jsonTree = [];

  data.forEach(v => {
    idMap[v[id]] = v;
  });

  data.forEach(v => {
    let parent = idMap[v[parentId]];
    if (parent) {
      !parent[children] && (parent[children] = []);
      parent[children].push(v);
    } else {
      jsonTree.push(v);
    }
  });
  return jsonTree;
}

areaList({
  pageNum: 1,
  pageSize: 1000
}).then(res => {
  let list = [];
  for (let i of res.data) {
    delete i.children;
    if (i.name === '江苏省') {
      continue;
    }
    list.push(i);
  }
  areaOptions.value = jsonTree(list, {
    id: "id",
    parentId: "pId",
    children: "children"
  });
  console.log('this.areaOptions', areaOptions);
})
if (route.query.id) {
  universityDetail(route.query.id).then(res => {
    curName.value = res.data.name
    curData.value = res.data
  })
}
dictType('/hz_university_level').then(res => {
  res.data.forEach((item, i) => {
    if (item.dictValue == route.query.cid) {
      curLabel.value = item.dictLabel
    }
  });
})
dictType('/school_grades').then(res => {
  grades.value = res.data
})
dictType('/physical_level').then(res => {
  physical.value = res.data
})
dictType('/primary_subject').then(res => {
  primary.value = res.data
})
dictType('/elective_subject').then(res => {
  elective.value = res.data
})
dictType('/english_level').then(res => {
  english.value = res.data
})
dictType('/hz_school_level').then(res => {
  school.value = res.data
})
dictType('/hz_score_level').then(res => {
  score.value = res.data
})
dictType('/competition_category').then(res => {
  competition.value = res.data
})
dictType('/award_level').then(res => {
  award.value = res.data
})
dictType('/award_level_2').then(res => {
  award2.value = res.data
})
dictType('/award_level_3').then(res => {
  award3.value = res.data
})
dictType('/advantage_type').then(res => {
  advantage.value = res.data
})
dictType('/award_rank').then(res => {
  rank.value = res.data
})

universityList({
  pageNum: 1,
  pageSize: 1000
}).then(res => {
  res.rows.forEach((item, i) => {
    selectOptions1.value.push({
      label: item.name,
      value: item.id
    })
  });
})

function getSchoolList(areaId) {
  form.value.gradeSchool = null;
  selectOptions3.value = [];
  schoolList({
    pageNum: 1,
    pageSize: 1000,
    areaId: areaId
  }).then(res => {
    res.rows.forEach((item, i) => {
      selectOptions3.value.push({
        label: item.name,
        value: item.id,
        obj: item
      })
    });
  })
}

function noSchool(){
  dialog.info({
    title: "客服信息",
    content: "如没有找到对应高中，请联系李老师：13347708658",
  })
}

dictType('/majors_category').then(res => {
  res.data.forEach((item, i) => {
    selectOptions2.value.push({
      label: item.dictLabel,
      value: item.dictValue
    })
  });
})
dictType('/review_type').then(res => {
  res.data.forEach((item, i) => {
    selectOptions4.value.push({
      label: item.dictLabel,
      value: item.dictValue
    })
  });
})

function toPage(path) {
  router.push(path)
}

function toSchoolList(id) {
  router.push('/school/' + id)
}

function back() {
  if (page.value > 0) {
    page.value--;
  }
}

function next() {
  let nowPage = page.value;
  nextTick(() => {
    if (page.value === 0) {
      formRefBase.value?.validate((errors) => {
        if (!errors) {
          page.value++
        } else {
          message.error("请完善表单信息");
        }
      });
    } else {
      let formRef = formRefs.value[nowPage - 1];
      formRef.validate((errors) => {
        if (!errors) {
          page.value++
        } else {
          message.error("请完善表单信息");
        }
      });
    }
    let nextPage = nowPage + 1;
    console.log('nextPage', nextPage);
    let awardType = awardTypes.value.types[nextPage - 1];
    if (awardType.awards.length === 0) {
      addAward(awardType);
    }
  })
}

function save() {
  let formRef = formRefs.value[page.value - 1];
  if (startPage.value === 0 && endPage.value === 0) {
    formRef = formRefBase.value;
  }
  formRef.validate((errors) => {
    if (errors) {
      message.error("请完善表单信息");
      return;
    }

    dialog.warning({
      title: '提示',
      positiveText: '确定',
      negativeText: '我再看看',
      content: '您已完成测评信息的填写，所填信息无修改请点“确认”，需再次确认填写内容请点“上一页”',
      onPositiveClick: () => {
        console.log('save', form, awardTypes)
        let reviewMain = Object.assign({}, form.value);
        if (reviewMain.baseUniversityId) {
          reviewMain.baseUniversityId = reviewMain.baseUniversityId.join(',');
        }
        if (reviewMain.baseMajor) {
          reviewMain.baseMajor = reviewMain.baseMajor.join(',');
        }
        if (reviewMain.baseElectiveSubject) {
          reviewMain.baseElectiveSubject = reviewMain.baseElectiveSubject.join(',');
        }
        let reviewId = "";
        addReview(reviewMain).then(async res => {
          console.log('save result:', res);
          reviewId = res.data;
          for (let awardType of awardTypes.value.types) {
            for (let award of awardType.awards) {
              let reviewAward = Object.assign({}, award);
              reviewAward.reviewId = reviewId;
              reviewAward.reviewType = awardType.id;
              if (reviewAward.raceOrg && Array.isArray(reviewAward.raceOrg)) {
                reviewAward.raceOrg = reviewAward.raceOrg.join(',');
              }
              console.log('save review award', reviewAward);
              await addReviewAward(reviewAward)
            }
          }
          console.log('all save');
          toPage('/settlement?id=' + reviewId);
        })
      }
    });
  });
}
</script>

<style lang="scss">
.check-area {
  .row {
    .n-form-item-label__text {
      font-size: 0.4375rem;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: 600;
      color: #081D5D;
      //width: 2.4rem;
      //text-align: right;

      display: inline-block;
      width: 4em;
      text-align: justify;
      text-align-last: justify;
    }
  }
}
</style>

<style lang="scss" scoped>
.breadcrumb {
  width: 37.5rem;
  margin: 0 auto;
  margin-top: 0.75rem;
}

.title {
  font-size: 1.25rem;
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: bold;
  color: #061B5C;
  line-height: 1.8125rem;
  letter-spacing: 0.03125rem;
  text-align: center;
  margin-top: 1.96875rem;
  margin-bottom: 0.625rem;
}

.intro {
  font-size: 0.375rem;
  font-family: SourceHanSansCN, SourceHanSansCN;
  color: #666666;
  line-height: 0.5625rem;
  text-align: center;
  max-width: 29.84375rem;
  margin: 0 auto;
  margin-bottom: 1.0625rem;
}

.btn-tips {
  font-size: 0.3rem;
  color: #666666;
  text-align: center;
  margin-top: 0.2rem;
}

.btns {
  display: flex;
  justify-content: center;

  a {
    width: 5.25rem;
    height: 1.8125rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 0.0625rem;

    h2 {
      font-size: 0.5625rem;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: bold;
      color: #FFFFFF;
    }

    p {
      font-size: 0.375rem;
      font-family: SourceHanSansCN, SourceHanSansCN;
      color: #FFFFFF;
    }
  }

  .red {
    background: linear-gradient(270deg, #d14538 0%, #F37457 100%);
    margin-right: 0.78125rem;
  }

  .blue {
    background: linear-gradient(270deg, #061B5C 0%, #33457B 100%);
  }
}

.container {
  max-width: 37.5rem;
  min-height: 21.0625rem;
  margin: 0 auto;
  margin-top: 0.5rem;
  background: #FFFFFF;
  box-shadow: 0px 0 0.125rem 0px rgba(0, 0, 0, 0.09);
  border-radius: 0.25rem;
  padding: 0.71875rem;

  .d-title {
    align-items: center;
    margin-bottom: 0.75rem;

    img {
      width: 0.75rem;
      height: 0.75rem;
      margin-right: 0.1875rem;
    }

    p {
      font-size: 0.53125rem;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: 600;
      color: #081D5D;
    }
  }

  .tabs {
    justify-content: space-between;

    .btn {
      width: calc(50% - 0.375rem);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.1875rem;
      padding: 0.75rem 0;
      cursor: pointer;

      &:first-child {
        background: rgba(230, 99, 75, 0.12);
        font-size: 0.5625rem;
        font-family: SourceHanSansCN, SourceHanSansCN;
        font-weight: 600;
        color: #d14538;
      }

      &:last-child {
        background: rgba(29, 48, 108, 0.12);
        font-size: 0.5625rem;
        font-family: SourceHanSansCN, SourceHanSansCN;
        font-weight: 600;
        color: #061B5C;
      }
    }
  }

  .hl-f {
    color: #e3352d !important;
    font-weight: bold !important;
    font-size: 0.5rem !important;
  }

  .hl-f-t {
    color: #999 !important;
    font-weight: bold !important;
    font-size: 0.4rem !important;

    a {
      color: #e3352d !important;
      cursor: pointer;
      font-weight: bold !important;
      font-size: 0.4rem !important;
    }
  }

  .check-area {
    background: #FFFFFF;
    background: #F9F9FC;
    border-radius: 0.1875rem;
    margin-top: 0.5rem;
    padding: 1rem;

    .award-item {
      margin-bottom: 0.5rem;
      margin-top: 0.5rem;
      border-bottom: 1px dashed #909090;
    }

    .del-btn {
      margin-left: auto;
      font-size: 0.5rem;
      color: red;
      text-align: center;
      margin-bottom: 1rem;
    }

    .btn {
      width: 5rem;
      height: 1.4375rem;
      background: #d14538;
      border-radius: 0.0625rem;
      font-size: 0.4375rem;
      font-family: SourceHanSansCN, SourceHanSansCN;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin: 0 auto;
    }

    .row {
      margin-bottom: 1rem;

      .label {
        font-size: 0.4375rem;
        font-family: SourceHanSansCN, SourceHanSansCN;
        font-weight: 600;
        color: #081D5D;
        //margin-right: 0.7rem;
        width: 2.4rem;
        //text-align: right;
        padding-right: 12px;
      }

      .check-opt {
        font-size: 0.4375rem;
        font-family: SourceHanSansCN, SourceHanSansCN;
        color: #333333;
        flex-wrap: wrap;
        cursor: pointer;

        .item {
          display: flex;
          align-items: center;
          margin-right: 1.46875rem;
          position: relative;
          min-width: 2.3125rem;


          &.active {
            .check-box {
              background-color: #d14538 !important;
              border: 0 !important;
              position: relative;

              &:after {
                content: '';
                position: absolute;
                width: calc(100% - 8px);
                height: calc(100% - 8px);
                left: 4px;
                top: 4px;
                background-color: #fff;
                border-radius: 50%;
              }
            }
          }
        }

        .check-box {
          width: 0.4375rem;
          height: 0.4375rem;
          border: 1px solid #C0C5CA;
          border-radius: 50%;
          box-sizing: border-box;
          margin-right: 0.125rem;
        }
      }
    }
  }

  .awards {
    background: #FFFFFF;
    background: #F9F9FC;
    border-radius: 0.1875rem;
    margin-top: 0.5rem;
    padding: 0.5625rem;

    &-title {
      font-size: 0.46875rem;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: 500;
      color: #333333;
      height: 1.3125rem;
      line-height: 1.3125rem;
      padding-left: 0.5rem;
      box-sizing: border-box;
      background: linear-gradient(270deg, rgba(255, 255, 255, 0.36) 0%, rgba(160, 178, 233, 0.36) 100%);
    }

    .opts {
      flex-wrap: wrap;
      justify-content: space-between;

      .item {
        width: 8.125rem;
        height: 4.40625rem;
        background: #FFFFFF;
        border-radius: 0.125rem;
        border: 1px solid #ECECF3;
        margin-top: 0.75rem;
        cursor: pointer;
        position: relative;

        .checked {
          position: absolute;
          width: 0.6875rem;
          height: 0.625rem;
          background: #d14538;
          right: 0;

          svg {
            width: 0.6875rem;
            color: #fff;

          }
        }

        &.active {
          border-color: #d14538;
        }

        .head {
          font-size: 0.4375rem;
          font-family: SourceHanSansCN, SourceHanSansCN;
          font-weight: 500;
          color: #081D5D;
          height: 1.375rem;
          line-height: 1.375rem;
          border-bottom: 1px solid #ECECF3;
          padding: 0 0.5rem;
        }

        p {
          font-size: 0.4375rem;
          font-family: SourceHanSansCN, SourceHanSansCN;
          color: #333333;
          padding: 0 0.5rem;
          margin-top: 0.375rem;
        }
      }
    }
  }

  .foot {
    justify-content: flex-end;
    margin-top: 0.75rem;

    .btn {
      width: 5rem;
      height: 1.4375rem;
      margin-right: 0.3rem;
      background: #d14538;
      border-radius: 0.0625rem;
      font-size: 0.4375rem;
      font-family: SourceHanSansCN, SourceHanSansCN;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}


@media screen and (max-width: 1024px) {
  .breadcrumb {
    display: none;
  }

  .container {
    margin-top: 40px;
  }
}

@media screen and (max-width: 835px) {
  .breadcrumb {
    display: none;
  }
}

@media screen and (max-width: 680px) {
  .container {
    width: 100%;
    width: calc(100% - 30px);
    margin: 15px auto;

    .tabs {
      display: inline !important;

      .btn {
        width: 100%;

        &:last-child {
          margin-top: 15px;
        }
      }
    }
  }
}

.mt-25 {
  margin-top: 0.78125rem;
}

.mt-16 {
  margin-top: 0.5rem;
}
</style>
