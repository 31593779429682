import {getToken, setToken} from '@/util/auth'

const user = {
    state: {
        token: getToken(),
    },

    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token;
            setToken(token);
        },
    },

    actions: {}
};

export default user
