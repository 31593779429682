<template>
  <n-layout>
    <n-layout-content>
      <div class="bg h-full">
        <Header/>
        <div style="min-height: calc(100% - 5.875rem); padding-bottom: 1.25rem;">
          <div class="breadcrumb">
            <n-breadcrumb>
              <n-breadcrumb-item @click="toPage('/me')" separator=">">个人中心</n-breadcrumb-item>
              <n-breadcrumb-item>我的综评</n-breadcrumb-item>
            </n-breadcrumb>
          </div>
          <div class="container">
            <div class="d-title flex">
              <img src="../../assets/images/js.png">
              <p>综评详情</p>
            </div>
            <div class="result">
              <img src="../../assets/images/result.png">
              <p>已支付，订单可在“个人中心”>“我的订单”中查看</p>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    </n-layout-content>
  </n-layout>
</template>

<script setup>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import {useRouter} from 'vue-router'
import {myListReview} from '@/api/api'
import {ref} from "vue";

const router = useRouter()
const list = ref([])

function goDetail(item) {
  router.push('/settlement?id=' + item.id)
}

myListReview({
  pageNum: 1,
  pageSize: 1000
}).then(res => {
  console.log('myListReview', res);
  list.value = res.rows;
})

function toPage(path) {
  router.push(path)
}
</script>

<style lang="scss" scoped>
.breadcrumb {
  width: 37.5rem;
  margin: 0 auto;
  margin-top: 0.75rem;
}

.container {
  max-width: 37.5rem;
  min-height: 21.0625rem;
  margin: 0 auto;
  margin-top: 0.5rem;
  background: #FFFFFF;
  box-shadow: 0px 0 0.125rem 0px rgba(0, 0, 0, 0.09);
  border-radius: 0.25rem;
  padding: 0.71875rem;
  position: relative;

  .result {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 100%;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    img {
      width: 4.5rem;
    }

    p {
      width: 13.21875rem;
      font-size: 0.4375rem;
      font-family: SourceHanSansCN, SourceHanSansCN;
      color: #061B5C;
      text-align: center;
    }
  }

  .d-title {
    align-items: center;
    margin-bottom: 0.75rem;

    img {
      width: 0.75rem;
      height: 0.75rem;
      margin-right: 0.1875rem;
    }

    p {
      font-size: 0.53125rem;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: 600;
      color: #081D5D;
    }
  }

  .base-area {
    .a-title {
      font-size: 0.46875rem;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: 500;
      color: #333333;
      height: 1.3125rem;
      line-height: 1.3125rem;
      padding-left: 0.5rem;
      box-sizing: border-box;
      background: linear-gradient(270deg, rgba(255, 255, 255, 0.36) 0%, rgba(160, 178, 233, 0.36) 100%);
    }

    .info-awards {
      padding-bottom: 0.5rem;
      flex-wrap: wrap;

      .item {
        width: 8.125rem;
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
        background: #FFFFFF;
        border-radius: 0.125rem;
        border: 1px solid #ECECF3;
        margin-top: 0.75rem;
        cursor: pointer;
        position: relative;
        padding-bottom: 0.5rem;

        .checked {
          position: absolute;
          width: 0.6875rem;
          height: 0.625rem;
          background: #d14538;
          right: 0;

          svg {
            width: 0.6875rem;
            color: #fff;

          }
        }

        &.active {
          border-color: #d14538;
        }

        .head {
          font-size: 0.4375rem;
          font-family: SourceHanSansCN, SourceHanSansCN;
          font-weight: 500;
          color: #081D5D;
          height: 1.375rem;
          line-height: 1.375rem;
          border-bottom: 1px solid #ECECF3;
          padding: 0 0.5rem;
        }

        p {
          font-size: 0.4375rem;
          font-family: SourceHanSansCN, SourceHanSansCN;
          color: #333333;
          padding: 0 0.5rem;
          margin-top: 0.375rem;
          display: -webkit-box;
          -webkit-line-clamp: 3; /* 设置显示的行数 */
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }

    .info {
      flex-wrap: wrap;

      .item {
        padding: 0.5rem;

        .label {
          font-size: 0.46875rem;
          font-family: SourceHanSansCN, SourceHanSansCN;
          font-weight: 500;
          color: #081D5D;
        }

        .value {
          font-size: 0.46875rem;
          font-family: SourceHanSansCN, SourceHanSansCN;
          color: #333333;
          margin-top: 0.125rem;
        }
      }
    }


  }

  .list {
    .item {
      .row,
      .row-2 {
        justify-content: space-between;
      }

      .row {
        align-items: center;

        .left {
          font-size: 0.5rem;
          font-family: SourceHanSansCN, SourceHanSansCN;
          color: #061B5C;
        }

        .right {
          font-size: 0.8125rem;
          font-family: Rubik, Rubik;
          font-weight: 600;
          color: #061B5C;

          span {
            font-size: 0.4375rem;
          }
        }
      }

      .row-2 {
        .left,
        .right {
          font-size: 0.5rem;
          font-family: SourceHanSansCN, SourceHanSansCN;
          color: #999999;
        }

        .right {
          color: #FF6E00;
        }
      }

      background: #F9F9FC;
      border-radius: 0.1875rem;
      padding: 0.5rem 0.75rem;
      margin-bottom: 0.75rem;
    }
  }

  .foot {
    justify-content: flex-end;
    margin-top: 0.75rem;

    .btn {
      width: 5rem;
      height: 1.4375rem;
      background: #d14538;
      border-radius: 0.0625rem;
      font-size: 0.4375rem;
      font-family: SourceHanSansCN, SourceHanSansCN;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 1024px) {
  .breadcrumb {
    display: none;
  }
}

@media screen and (max-width: 680px) {
  .container {
    width: calc(100% - 30px);
    margin: 0 auto;
    margin-top: 15px;
  }
}

@media screen and (max-width: 610px) {
  .info-awards {
    .item {
      width: 100% !important;
    }
  }
}

.mt-25 {
  margin-top: 0.78125rem;
}

.mt-16 {
  margin-top: 0.5rem;
}
</style>