<template>
  <div class="plan-page" ref="wrapper" v-bind:style="{height:setHeight?setHeight+'px':'auto'}">
    <slot></slot>
    <div class="height-tip">{{ actualHeight }}px</div>
  </div>
</template>

<script>

export default {
  name: "pageContainer",
  props: {
    height: {
      type: Number,
      default: null
    },
    title: {
      type: String,
      default: ""
    },
  },
  data() {
    return {
      setHeight: this.height,
      actualHeight: 0
    }
  },
  computed: {},
  methods: {},
  mounted() {
    this.$nextTick(() => {
      console.log('获取page内容高度', this.$refs.wrapper.clientHeight);
      this.actualHeight = this.$refs.wrapper.clientHeight;
      if (this.actualHeight < 794) {
        this.setHeight = 794;
      } else {
        let pageCount = Math.ceil(this.actualHeight / 794);
        this.setHeight = pageCount * 794;
      }
    })
  },
}
</script>

<style lang="scss" scoped>
.height-tip {
  position: absolute;
  top: 0;
  right: 20px;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 5px 10px;
  font-size: 12px;
  z-index: 9999;
  border-radius: 5px;
  margin: 10px;
  display: none;
}
</style>
