import axios from 'axios';
import {showMessage} from "./status";
import {removeToken, getToken} from '@/util/auth'
import router from "@/router";

import {createDiscreteApi} from 'naive-ui'

const {message} = createDiscreteApi(['message'])

// 设置接口超时时间
axios.defaults.timeout = 60000;

axios.defaults.baseURL = process.env.VUE_APP_BASE_API;

let token = ''

// let showMsg = false

//http request 拦截器
axios.interceptors.request.use(
    async config => {
        config.headers['Authorization'] = 'Bearer ' + getToken()
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

// 封装 GET POST 请求并导出
export function request(url = '', params = {}, type = 'POST') {
    //设置 url params type 的默认值
    return new Promise((resolve, reject) => {
        let promise

        if (type.toUpperCase() === 'GET') {
            promise = axios({
                url,
                params
            })
        } else if (type.toUpperCase() === 'POST') {
            promise = axios({
                method: 'POST',
                url,
                data: params
            })
        } else if (type.toUpperCase() === 'PUT') {
            promise = axios({
                method: 'PUT',
                url,
                data: params
            })
        }

        //处理返回
        promise.then(res => {
            if (res.data.code === 401) {
                console.log('登录信息已过期');
                removeToken();
                router.push("/login");
            }
            if (res.data.code === 500) {
                if (res.data.msg) {
                    message.warning(res.data.msg)
                }
                reject();
            } else {
                resolve(res.data)
            }
        })
    })
}
