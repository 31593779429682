import {request} from './axios'

export function dictType(type, data) {
    return request(`/api/dict/data/type${type}`, data, 'GET')
}

export function areaList(data) {
    return request('/api/area/list', data, 'GET')
}

export function universityList(data) {
    return request('/api/university/list', data, 'GET')
}

export function universityDetail(id, data) {
    return request(`/api/university/${id}`, data, 'GET')
}

export function schoolList(data) {
    return request(`/api/school/list`, data, 'GET')
}

// 查询综评列表
export function listReview(data) {
    return request('/api/review/list', data, 'GET');
}

// 查询我的订单列表
export function myPayment(data) {
    return request('/api/my/payment/list', data, 'GET');
}

export function myPaymentByReviewId(id, data) {
    return request(`/api/my/payment/byReview/${id}`, data, 'GET');
}

// 新增综评
export function addPayment(data) {
    return request('/api/my/payment', data, 'POST');
}

export function checkPaidState(id) {
    return request('/api/my/payment/paid/status?id=' + id, null, 'POST');
}

// 查询我的综评列表
export function myListReview(data) {
    return request('/api/my/review/list', data, 'GET');
}

// 查询我的积分列表
export function myPointReview(data) {
    return request('/api/my/point/list', data, 'GET');
}

// 查询我的基本信息
export function myInfo(data) {
    return request('/api/my/info', data, 'POST');
}

// 查询综评详细
export function getReview(id) {
    return request('/api/my/review/' + id, {}, 'GET');
}

// 查询综评详细报告
export function getReviewReport(id) {
    return request('/api/my/review/' + id + '/report', {}, 'GET');
}

// 新增综评
export function addReview(data) {
    return request('/api/my/review', data, 'POST');
}

export function getDownloadPdfUrl(id) {
    return request('/api/my/review/download/url', {id: id}, 'GET');
}

// 查询综评奖项列表
export function listReviewAward(data) {
    return request('/api/my/review/award/list', data, 'GET');
}

// 查询综评奖项详细
export function getReviewAward(id) {
    return request('/api/my/review/award/' + id, data, 'GET');
}

// 新增综评
export function addReviewAward(data) {
    return request('/api/my/review/award/', data, 'POST');
}

// 发送验证码
export function sendAuthCode(data) {
    return request('/api/login/authCode', data, 'POST');
}

export function login(data) {
    return request('/api/login/login', data, 'POST');
}

export function wxLogin(data) {
    return request('/api/login/wxLogin', data, 'POST');
}

export function wxLoginCheck(id) {
    return request('/api/login/wxLogin/check?id=' + id, null, 'POST');
}