<template>
  <n-layout>
    <n-layout-content>
      <div class="bg h-full">
        <Header/>
        <div style="min-height: calc(100% - 5.875rem); padding-bottom: 1.25rem;">
          <div class="breadcrumb">
            <n-breadcrumb>
              <n-breadcrumb-item @click="toPage('/home')" separator=">">首页</n-breadcrumb-item>
              <n-breadcrumb-item @click="toPage('/me')" separator=">">个人中心</n-breadcrumb-item>
              <n-breadcrumb-item @click="toPage('/order')" separator=">">我的综评</n-breadcrumb-item>
              <n-breadcrumb-item>综评详情</n-breadcrumb-item>
            </n-breadcrumb>
          </div>
          <div class="container" v-if="loaded">
            <div class="d-title flex">
              <img src="../../assets/images/js.png">
              <p>综评详情</p>
            </div>
            <div class="base-area" v-if="model.baseName">
              <div class="a-title">
                基础信息
              </div>
              <div class="info flex">
                <div class="item">
                  <div class="label">
                    学生姓名
                  </div>
                  <div class="value">
                    {{ model.baseName }}
                  </div>
                </div>
                <div class="item">
                  <div class="label">
                    预估分数
                  </div>
                  <div class="value">
                    {{ model.baseScore }}
                  </div>
                </div>
                <div class="item">
                  <div class="label">
                    意向高校
                  </div>
                  <div class="value">
                    {{ getTypeName(model.baseUniversityId, 'selectOptions1') }}
                  </div>
                </div>
                <div class="item">
                  <div class="label">
                    意向专业
                  </div>
                  <div class="value">
                    {{ getTypeName(model.baseMajor, 'selectOptions2') }}
                  </div>
                </div>
                <div class="item">
                  <div class="label">
                    年级
                  </div>
                  <div class="value">
                    {{ getTypeName(model.baseGrades, 'grades') }}
                  </div>
                </div>
                <div class="item">
                  <div class="label">
                    身体素质
                  </div>
                  <div class="value">
                    {{ getTypeName(model.basePhysicalLevel, 'physical') }}
                  </div>
                </div>
                <div class="item">
                  <div class="label">
                    主选科目
                  </div>
                  <div class="value">
                    {{ getTypeName(model.basePrimarySubject, 'primary') }}
                  </div>
                </div>
                <div class="item">
                  <div class="label">
                    选修科目
                  </div>
                  <div class="value">
                    {{ getTypeName(model.baseElectiveSubject, 'elective') }}
                  </div>
                </div>
                <div class="item">
                  <div class="label">
                    英语能力
                  </div>
                  <div class="value">
                    {{ getTypeName(model.baseEnglishLevel, 'english') }}
                  </div>
                </div>
                <div class="item">
                  <div class="label">
                    高中信息
                  </div>
                  <div class="value">
                    {{ getTypeName(model.gradeSchool, 'selectOptions3') }}
                  </div>
                </div>
                <div class="item">
                  <div class="label">
                    学校级别
                  </div>
                  <div class="value">
                    {{ getTypeName(model.gradeSchoolLevel, 'school') }}
                  </div>
                </div>
                <div class="item">
                  <div class="label">
                    年级成绩
                  </div>
                  <div class="value">
                    {{ getTypeName(model.gradeScoreLevel, 'score') }}
                  </div>
                </div>
              </div>
            </div>
            <div class="base-area" v-for="awardType in awardTypes">
              <div v-if="awardType.awards.length>0">
                <div class="a-title">
                  {{ awardType.name }}
                </div>
                <div class="info-awards flex">
                  <div class="item" v-for="award in awardType.awards">
                    <div class="head">
                      {{ getTypeName(award.raceCategory, 'competition', award.raceName) }}
                    </div>
                    <div v-if="awardType.id==='8'">
                      <p v-if="award.file">附件：<a target="_blank" :href="getFile(award.file)">查看附件</a></p>
                      <p v-if="award.raceDate">发证日期：{{ award.raceDate }}</p>
                    </div>
                    <div v-else>
                      <section v-if="award.raceLevel">
                        <p v-if="award.reviewType===9&&award.advantageType<=3">奖项级别：{{ getTypeName(award.raceLevel, 'award_2') }}</p>
                        <p v-else-if="award.reviewType===9&&award.advantageType>3">奖项级别：{{
                            getTypeName(award.raceLevel, 'award_3')
                          }}</p>
                        <p v-else>奖项级别：{{ getTypeName(award.raceLevel, 'award') }}</p>
                      </section>
                      <p v-if="award.raceRank">奖项等级：{{ getTypeName(award.raceRank, 'rank') }}</p>
                      <p v-if="award.raceOrg">组织单位：{{ award.raceOrg }}</p>
                      <p v-if="award.raceNum">证书编号：{{ award.raceNum }}</p>
                      <p v-if="award.raceDate">发证日期：{{ award.raceDate }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="base-area" v-for="item in report">
              <div v-if="item.universityList.length>0">
                <div class="a-title">
                  根据你的 {{ item.title }} ，为你匹配以下高校：
                </div>
                <div class="info-awards flex">
                  <div class="item item-url" v-for="i in item.universityList" @click="goUniversity(i.id)">
                    <div class="head">
                      {{ i.name }}
                    </div>
                    <p>{{ i.intro }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="base-area" v-if="allEmpty">
              <div class="info-awards-empty">
                <n-empty description="暂时还没有匹配高校，继续加油~"></n-empty>
              </div>
            </div>
            <div class="go-report" @click="goReport(model)" v-if="model.type===1">
              获取您的完整测评报告
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    </n-layout-content>
  </n-layout>
</template>

<script setup>
import {ref, onMounted, nextTick} from 'vue'
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import {useRoute, useRouter} from 'vue-router'
import {
  dictType,
  getReview, getReviewReport,
  listReviewAward, myPaymentByReviewId, schoolList, universityList
} from '@/api/api'

const router = useRouter()
const route = useRoute()

const allEmpty = ref(true)
const loaded = ref(false)
const model = ref({})
const report = ref([
  {id: "2", title: '学科成绩优异项', universityList: []},
  {id: "3", title: '学科竞赛奖项', universityList: []},
  {id: "4", title: '全国竞赛活动奖项', universityList: []},
  {id: "5", title: '全省竞赛活动奖项', universityList: []},
  {id: "6", title: '其他竞赛活动奖项', universityList: []},
  {id: "7", title: '表彰类活动奖项', universityList: []},
  {id: "8", title: '创新潜质项', universityList: []},
  {id: "9", title: '综评档案优势得分项', universityList: []},
]);

const awardTypes = ref(
    [
      {name: '五大学科竞赛奖项', id: '3', inputName: false, awards: []},
      {name: '全国竞赛活动奖项', id: '4', inputName: false, awards: []},
      {name: '全省竞赛活动奖项', id: '5', inputName: false, awards: []},
      {name: '其他竞赛活动奖项', id: '6', inputName: false, awards: []},
      {name: '表彰类活动奖项', id: '7', inputName: false, awards: []},
      {name: '创新潜质项', id: '8', inputName: true, awards: []},
      {name: '综评档案优势得分项', id: '9', inputName: true, awards: []},
    ]
)

const types = ref({
  grades: [],
  physical: [],
  primary: [],
  elective: [],
  english: [],
  school: [],
  score: [],
  competition: [],
  award: [],
  award_2: [],
  award_3: [],
  rank: [],
  // 高校信息
  selectOptions1: [],
  selectOptions2: [],
  selectOptions3: [],
  selectOptions4: []
})

const id = ref(route.query.id)

function goUniversity(id) {
  router.push('/detail?id=' + id)
}

function goReport(item) {
  if (item.type === 2) {
    router.push('Evaluation?type=1')
    return;
  }
  myPaymentByReviewId(item.id).then(res => {
    console.log('myPaymentByReviewId', res);
    if (res.data && res.data.paid === 1) {
      router.push('/report?id=' + item.id)
    } else {
      router.push('/settlement/payment?id=' + item.id)
    }
  })
}

function getFile(file) {
  return process.env.VUE_APP_BASE_API + file;
}

function getTypeName(val, typeName, res) {
  let names = [];
  let vals = [];
  if (val) {
    if (val.toString().indexOf(',') > -1) {
      console.log('数组类型', val);
      vals = val.split(',');
    } else {
      vals.push(val);
    }
    for (let v of vals) {
      if (types && types.value[typeName]) {
        let item = types.value[typeName].find(i => {
          return i.dictValue.toString() === v.toString()
        });
        if (item) {
          names.push(item.dictLabel);
        }
      }
    }
  }
  let result = names.join(',');
  if (result) {
    return result;
  } else {
    return res;
  }
}

function getUniversity(id) {
  return types.value.selectOptions1.find(i => i.dictValue === id)
}

onMounted(async () => {
  let reviewRes = await getReview(id.value);
  console.log('review:', reviewRes);
  model.value = reviewRes.data;
  let awardRes = await listReviewAward({
    reviewId: model.value.id,
    pageNum: 1,
    pageSize: 1000
  });
  console.log('awardRes:', awardRes);
  let awards = awardRes.rows;
  for (let awardType of awardTypes.value) {
    let reviewAwards = awards.filter(i => {
      return i.reviewType.toString() === awardType.id
    });
    awardType.awards = awardType.awards.concat(reviewAwards);
  }
  console.log('awardTypes.value', awardTypes.value);

  let universityRes = await universityList({
    pageNum: 1,
    pageSize: 1000
  })
  universityRes.rows.forEach((item, i) => {
    types.value.selectOptions1.push({
      dictLabel: item.name,
      dictIntro: item.intro,
      dictValue: item.id
    })
  });

  let schoolRes = await schoolList({
    pageNum: 1,
    pageSize: 1000
  })
  schoolRes.rows.forEach((item, i) => {
    types.value.selectOptions3.push({
      dictLabel: item.name,
      dictValue: item.id,
    })
  });

  let reportRes = await getReviewReport(id.value);
  let data = reportRes.data;
  console.log('review report', reportRes, [...new Set(data.Match2)]);
  let allUniversity = [];
  let matchList = [
    [...new Set(data.Match2)],
    [...new Set(data.Match3)],
    [...new Set(data.Match4)],
    [...new Set(data.Match5)],
    [...new Set(data.Match6)],
    [...new Set(data.Match7)],
    [...new Set(data.Match8)],
    [...new Set(data.Match9)],
  ];
  for (let i = 0; i < 8; i++) {
    matchList[i].map(uId => {
      let u = getUniversity(uId);
      if (u) {
        allEmpty.value = false;
        allUniversity.push(u);
        report.value[i].universityList.push({
          name: u.dictLabel,
          intro: u.dictIntro,
          id: u.dictValue
        })
      }
    })
  }
  console.log('report result:', report);

  loaded.value = true;
})


dictType('/school_grades').then(res => {
  types.value.grades = res.data
})
dictType('/physical_level').then(res => {
  types.value.physical = res.data
})
dictType('/primary_subject').then(res => {
  types.value.primary = res.data
})
dictType('/elective_subject').then(res => {
  types.value.elective = res.data
})
dictType('/english_level').then(res => {
  types.value.english = res.data
})
dictType('/hz_school_level').then(res => {
  types.value.school = res.data
})
dictType('/hz_score_level').then(res => {
  types.value.score = res.data
})
dictType('/competition_category').then(res => {
  types.value.competition = res.data
})
dictType('/award_level').then(res => {
  types.value.award = res.data
})
dictType('/award_level_2').then(res => {
  types.value.award_2 = res.data
})
dictType('/award_level_3').then(res => {
  types.value.award_3 = res.data
})
dictType('/award_rank').then(res => {
  types.value.rank = res.data
})

dictType('/majors_category').then(res => {
  res.data.forEach((item, i) => {
    types.value.selectOptions2.push({
      dictLabel: item.dictLabel,
      dictValue: item.dictValue
    })
  });
})
dictType('/review_type').then(res => {
  res.data.forEach((item, i) => {
    types.value.selectOptions4.push({
      dictLabel: item.dictLabel,
      dictValue: item.dictValue
    })
  });
})

function toPage(path) {
  router.push(path)
}

</script>

<style lang="scss" scoped>
.breadcrumb {
  width: 37.5rem;
  margin: 0 auto;
  margin-top: 0.75rem;
}

.go-report {
  background-color: #d14538;
  color: #fff;
  font-size: 0.6rem;
  text-align: center;
  padding: 0.2rem;
  font-weight: bold;
  width: 10rem;
  margin: 0 auto;
  border-radius: 10px;
  cursor: pointer;
}

.container {
  max-width: 37.5rem;
  min-height: 21.0625rem;
  margin: 0 auto;
  margin-top: 0.5rem;
  background: #FFFFFF;
  box-shadow: 0px 0 0.125rem 0px rgba(0, 0, 0, 0.09);
  border-radius: 0.25rem;
  padding: 0.71875rem;
  position: relative;

  .result {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 100%;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    img {
      width: 4.5rem;
    }

    p {
      width: 13.21875rem;
      font-size: 0.4375rem;
      font-family: SourceHanSansCN, SourceHanSansCN;
      color: #061B5C;
      text-align: center;
    }
  }

  .d-title {
    align-items: center;
    margin-bottom: 0.75rem;

    img {
      width: 0.75rem;
      height: 0.75rem;
      margin-right: 0.1875rem;
    }

    p {
      font-size: 0.53125rem;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: 600;
      color: #081D5D;
    }
  }

  .base-area {
    .a-title {
      font-size: 0.46875rem;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: 500;
      color: #333333;
      height: 1.3125rem;
      line-height: 1.3125rem;
      padding-left: 0.5rem;
      box-sizing: border-box;
      background: linear-gradient(270deg, rgba(255, 255, 255, 0.36) 0%, rgba(160, 178, 233, 0.36) 100%);
    }

    .info-awards-empty {
      margin: 2rem;
    }

    .info-awards {
      padding-bottom: 0.5rem;
      flex-wrap: wrap;

      .item-url {
        cursor: pointer;
      }

      .item {
        width: 8.125rem;
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
        background: #FFFFFF;
        border-radius: 0.125rem;
        border: 1px solid #ECECF3;
        margin-top: 0.75rem;
        position: relative;
        padding-bottom: 0.5rem;

        .checked {
          position: absolute;
          width: 0.6875rem;
          height: 0.625rem;
          background: #d14538;
          right: 0;

          svg {
            width: 0.6875rem;
            color: #fff;

          }
        }

        &.active {
          border-color: #d14538;
        }

        .head {
          font-size: 0.4375rem;
          font-family: SourceHanSansCN, SourceHanSansCN;
          font-weight: 500;
          color: #081D5D;
          height: 1.375rem;
          line-height: 1.375rem;
          border-bottom: 1px solid #ECECF3;
          padding: 0 0.5rem;
          overflow: hidden; //超出的文本隐藏
          text-overflow: ellipsis; //溢出用省略号显示
          white-space: nowrap; //溢出不换行
        }

        p {
          font-size: 0.4375rem;
          font-family: SourceHanSansCN, SourceHanSansCN;
          color: #333333;
          padding: 0 0.5rem;
          margin-top: 0.375rem;
          display: -webkit-box;
          -webkit-line-clamp: 3; /* 设置显示的行数 */
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }

    .info {
      flex-wrap: wrap;

      .item {
        padding: 0.5rem;

        .label {
          font-size: 0.46875rem;
          font-family: SourceHanSansCN, SourceHanSansCN;
          font-weight: 500;
          color: #081D5D;
        }

        .value {
          font-size: 0.46875rem;
          font-family: SourceHanSansCN, SourceHanSansCN;
          color: #333333;
          margin-top: 0.125rem;
        }
      }
    }


  }

  .list {
    .item {
      .row,
      .row-2 {
        justify-content: space-between;
      }

      .row {
        align-items: center;

        .left {
          font-size: 0.5rem;
          font-family: SourceHanSansCN, SourceHanSansCN;
          color: #061B5C;
        }

        .right {
          font-size: 0.8125rem;
          font-family: Rubik, Rubik;
          font-weight: 600;
          color: #061B5C;

          span {
            font-size: 0.4375rem;
          }
        }
      }

      .row-2 {
        .left,
        .right {
          font-size: 0.5rem;
          font-family: SourceHanSansCN, SourceHanSansCN;
          color: #999999;
        }

        .right {
          color: #FF6E00;
        }
      }

      background: #F9F9FC;
      border-radius: 0.1875rem;
      padding: 0.5rem 0.75rem;
      margin-bottom: 0.75rem;
    }
  }

  .foot {
    justify-content: flex-end;
    margin-top: 0.75rem;

    .btn {
      width: 5rem;
      height: 1.4375rem;
      background: #d14538;
      border-radius: 0.0625rem;
      font-size: 0.4375rem;
      font-family: SourceHanSansCN, SourceHanSansCN;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 1024px) {
  .breadcrumb {
    display: none;
  }
}

@media screen and (max-width: 680px) {
  .container {
    width: calc(100% - 30px);
    margin: 0 auto;
    margin-top: 15px;
  }
}

@media screen and (max-width: 610px) {
  .info-awards {
    .item {
      width: 100% !important;
    }
  }
}

.mt-25 {
  margin-top: 0.78125rem;
}

.mt-16 {
  margin-top: 0.5rem;
}
</style>
