<template>
  <n-layout>
    <n-layout-content>
      <div class="bg h-full">
        <Header/>
        <div style="min-height: calc(100% - 5.875rem);">
          <h1 class="title">江苏省高中生综评升学 AI自主测评</h1>
          <p class="intro text-center">本测评依据最新《江苏省关于普通高校综合评价招生改革试点工作的通知》和江苏省综合评价录取招生高校最新招生简章及录取结果。</p>
          <div class="btns">
            <a class="red" href="javascript:void(0)" @click="router.push('/Evaluation/intro')">
              <h2>测前须知</h2>
              <p>测前准备</p>
            </a>
            <a class="red" href="javascript:void(0)" @click="router.push('/Evaluation?type=1')">
              <h2>我要测评</h2>
              <p>获取综评升学报告</p>
            </a>
            <a class="red" href="http://www.jshzycedu.com/portal/course.html">
              <h2>课程学习</h2>
              <p>系统课程学习</p>
            </a>
            <a class="red" href="/Home#university-area">
              <h2>适配院校</h2>
              <p>奖项适配院校</p>
            </a>
          </div>
          <div class="directory">
            <div class="d-title flex">
              <img src="../../assets/images/directory.png">
              <p>江苏省综评院校名录</p>
            </div>
            <div class="types flex mt-25" v-for="level in universityLevel">
              <div class="type-btn">
                {{ level.dictLabel }}
              </div>
              <div class="list">
                <div class="type-container">
                  <div class="type-item" @click="toPageParams('/detail', item.levelId, item.id)"
                       v-for="item in level.schools">
                    {{ item.name }}
                  </div>
                </div>
              </div>
              <div class="read" @click="toPage(`/school/${level.dictValue}`)">
                查看
              </div>
            </div>
          </div>

          <div class="positioning" id="university-area">
            <div class="d-title flex">
              <img src="../../assets/images/positioning.png">
              <p>适配综评院校</p>
            </div>
            <div class="content">
              <div class="options">
                <div class="item" @click="active = item.dictValue" :class="{active: active == item.dictValue}"
                     v-for="item in category">
                  {{ item.dictLabel }}
                </div>
              </div>
              <div class="schools flex">
                <div class="item" :class="{'noborer': !item.id}"
                     @click="toPageParams('/evaluation', item.levelId, item.id)" v-for="item in surSchools">
                  <div class="head flex" v-if="item.id">
                    <div class="avatar">
                      <img :src="getImgUrl(item.logo)">
                    </div>
                    <p>{{ item.name }}</p>
                  </div>
                  <p class="s-intro">{{ item.intro }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    </n-layout-content>
  </n-layout>
</template>

<script setup>
import {ref, onMounted, watch} from 'vue'
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import {dictType, universityList} from '@/api/api'
import {useRoute, useRouter} from 'vue-router'

const router = useRouter()
const route = useRoute()
const universityLevel = ref([])
const category = ref([])
const active = ref('1')
const schools = ref([])
const surSchools = ref([])

function getImgUrl(path) {
  return process.env.VUE_APP_BASE_API + path;
}

const fetchData = async () => {
  try {
    const result = await dictType('/hz_university_level')
    const list = await universityList({
      pageNum: 1,
      pageSize: 1000
    })
    universityLevel.value = result.data
    universityLevel.value.forEach((l, i) => {
      l.schools = []
      list.rows.forEach((item, j) => {
        if (item.levelId == l.dictValue) {
          l.schools.push(item)
        }
      })
    })
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

const fetchCategory = async () => {
  try {
    const result = await dictType('/hz_university_category')
    const list = await universityList({
      pageNum: 1,
      pageSize: 1000
    })
    category.value = result.data
    schools.value = list.rows
    schools.value.forEach((item, i) => {
      if (item.categoryId.includes(active.value)) {
        surSchools.value.push(item)
      }
    });

    if (schools.value.length % 3 == 2) {
      surSchools.value.push({})
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

watch(active, (val) => {
  surSchools.value = []
  schools.value.forEach((item, i) => {
    if (item.categoryId.includes(active.value)) {
      surSchools.value.push(item)
    }
  });

  if (schools.value.length % 3 == 2) {
    surSchools.value.push({})
  }
})

onMounted(() => {
  fetchData()
  fetchCategory()
  console.log('route', route);
  if (route.fullPath.indexOf('#university-area') > -1) {
    active.value = "3";
  }
})

function toPage(path) {
  router.push(path)
}

function toPageParams(path, cid, id) {
  let startPage = 0;
  let endPage = 7;
  if (active.value === "1") {
    startPage = 0;
    endPage = 0;
  }
  if (active.value === "2") {
    startPage = 1;
    endPage = 1;
  }
  if (active.value === "3") {
    startPage = 2;
    endPage = 4;
  }
  if (active.value === "4") {
    startPage = 5;
    endPage = 5;
  }
  if (active.value === "5") {
    startPage = 6;
    endPage = 6;
  }
  router.push({
    path: path, query: {
      cid,
      id,
      type: 2,
      startPage,
      endPage
    }
  })
}
</script>

<style lang="scss" scoped>
.noborer {
  box-shadow: 0 0 0 0 !important;
  cursor: auto !important;
  pointer-events: none;
}

.title {
  font-size: 1.25rem;
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: bold;
  color: #061B5C;
  line-height: 1.8125rem;
  letter-spacing: 0.03125rem;
  text-align: center;
  margin-top: 1.96875rem;
  margin-bottom: 0.625rem;
}

.intro {
  font-size: 0.375rem;
  font-family: SourceHanSansCN, SourceHanSansCN;
  color: #666666;
  line-height: 0.5625rem;
  text-align: center;
  max-width: 29.84375rem;
  margin: 0 auto;
  margin-bottom: 1.0625rem;
}

.btns {
  display: flex;
  justify-content: center;

  a {
    width: 5.25rem;
    height: 1.8125rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 0.0625rem;

    h2 {
      font-size: 0.5625rem;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: bold;
      color: #FFFFFF;
    }

    p {
      font-size: 0.375rem;
      font-family: SourceHanSansCN, SourceHanSansCN;
      color: #FFFFFF;
    }
  }

  .red {
    background: linear-gradient(270deg, #d14538 0%, #F37457 100%);
    margin-right: 0.78125rem;
  }

  .blue {
    background: linear-gradient(270deg, #061B5C 0%, #33457B 100%);
  }
}

.d-title {
  align-items: center;

  img {
    width: 0.75rem;
    height: 0.75rem;
    margin-right: 0.1875rem;
  }

  p {
    font-size: 0.53125rem;
    font-family: SourceHanSansCN, SourceHanSansCN;
    font-weight: 600;
    color: #d14538;
  }
}


.directory {
  width: 37.5rem;
  height: 5.375rem;
  margin: 0 auto;
  background: #FFFFFF;
  box-shadow: 0px 0 0.125rem 0px rgba(0, 0, 0, 0.09);
  border-radius: 0.25rem;
  padding: 0.71875rem;
  box-sizing: border-box;
  margin-top: 1.5625rem;

  .types {
    justify-content: space-between;

    .type-btn {
      width: 3.125rem;
      height: 0.9375rem;
      line-height: 0.9375rem;
      background: linear-gradient(333deg, #FFF6F6 0%, #FFFFFF 100%);
      border-radius: 0.0625rem;
      border: 1px solid #d14538;
      font-size: 14px;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: bold;
      color: #d14538;
      text-align: center;
      box-sizing: border-box;
      margin-right: 1.25rem;
    }

    .list {
      position: relative;
      width: calc(100% - 3.125rem - 1.25rem - 1.25rem - 1.0625rem);
      white-space: nowrap;
      overflow: hidden;

      .type-container {
        width: 1920px;

        .type-item {
          float: left;
          font-size: 0.4375rem;
          font-family: SourceHanSansCN, SourceHanSansCN;
          font-weight: 400;
          color: #7B838C;
          line-height: 0.9375rem;
          margin-right: 1.5625rem;
          cursor: pointer;
        }
      }

      &:after {
        content: '';
        position: absolute;
        width: 4.375rem;
        height: 100%;
        right: 0;
        top: 0;
        background: linear-gradient(-270deg, rgba(255, 255, 255, 0.09) 0%, #fff 100%);
      }

    }

    .read {
      font-size: 0.5rem;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: 600;
      color: #3B83E7;
      line-height: 0.9375rem;
      cursor: pointer;
    }
  }
}

.positioning {
  max-width: 37.5rem;
  min-height: 21.0625rem;
  margin: 0 auto;
  margin-top: 0.9375rem;
  margin-bottom: 1.25rem;
  padding: 0.71875rem;
  background: #FFFFFF;
  box-shadow: 0px 0 0.125rem 0px rgba(0, 0, 0, 0.09);
  border-radius: 0.25rem;
  overflow: hidden;

  .content {
    margin-top: 0.75rem;

    .options {
      float: left;
      width: 7.5rem;
      background: #F6F6F8;
      border-radius: 0.1875rem;
      box-sizing: border-box;
      padding: 0.5rem 0.125rem;

      .item {
        font-size: 0.4375rem;
        font-family: SourceHanSansCN, SourceHanSansCN;
        font-weight: 500;
        color: #7B838C;
        padding: 0.25rem 0.375rem;
        cursor: pointer;

        &.active {
          background: #d14538;
          border-radius: 0.125rem;
          color: #fff;
        }
      }
    }

    .schools {
      float: right;
      width: calc(100% - 7.5rem - 0.75rem);
      flex-wrap: wrap;
      justify-content: space-between;

      .item {
        width: calc(33.333% - 0.5625rem);
        box-sizing: border-box;
        padding: 0.375rem;
        padding-bottom: 1rem;
        box-shadow: 0 0 0.125rem 0px rgba(0, 0, 0, 0.09);
        margin-bottom: 0.75rem;
        cursor: pointer;

        .head {
          align-items: center;

          .avatar {
            width: 1.125rem;
            height: 1.125rem;
            background: #FFFFFF;
            border-radius: 0.125rem;
            border: 0.03125rem solid #D4D9DF;
            margin-right: 0.375rem;
          }

          p {
            font-size: 0.4375rem;
            font-family: SourceHanSansCN, SourceHanSansCN;
            font-weight: 600;
            color: #333333;
          }
        }

        .s-intro {
          font-size: 0.375rem;
          font-family: SourceHanSansCN, SourceHanSansCN;
          color: #999999;
          line-height: 0.53125rem;
          margin-top: 0.375rem;
          display: -webkit-box;
          -webkit-line-clamp: 6; /* 设置显示的行数 */
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }

    .schools::after {
      content: '';
      width: calc(33.333% - 0.5625rem);
    }
  }
}

.mt-25 {
  margin-top: 0.78125rem;
}

.mt-16 {
  margin-top: 0.5rem;
}

@media screen and (max-width: 921px) {
  .directory {
    width: calc(100% - 30px);
    margin: 15px auto;
    height: auto;

    .types {
      display: inline;

      .type-btn {
        width: 100%;
        margin-top: 15px;
      }

      .list {
        width: 100%;

        &:after {
          display: none;
        }

        .type-container {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }

        .type-item {
          float: none !important;
          width: calc(50% - 10px);
          text-align: center;
          border: 1px solid rgba(0, 0, 0, 0.09);
          margin-top: 15px;
          margin-right: 0 !important;
        }
      }

      .read {
        text-align: center;
        border: 1px solid rgba(0, 0, 0, 0.09);
        margin-top: 9px;
      }
    }
  }

  .positioning {
    width: calc(100% - 30px);
    margin: 0 auto;
    padding-top: 15px;

    .options {
      float: none !important;
      width: 100% !important;

      .item {
        text-align: center;
      }

    }

    .schools {
      float: none !important;
      width: 100% !important;
      margin-top: 15px;
    }
  }
}

@media screen and (max-width: 628px) {
  .schools {
    .item {
      width: calc(50% - 0.5625rem) !important;
    }
  }
}
</style>
