<template>
  <n-layout>
    <n-layout-content>
      <div class="bg h-full">
        <Header/>
        <div style="min-height: calc(100% - 5.875rem); padding-bottom: 1.25rem;">
          <div class="breadcrumb">
            <n-breadcrumb>
              <n-breadcrumb-item @click="toPage('/me')" separator=">">个人中心</n-breadcrumb-item>
              <n-breadcrumb-item @click="toPage('/order')">我的综评</n-breadcrumb-item>
            </n-breadcrumb>
          </div>
          <div class="container">
            <div class="d-title flex">
              <img src="../../assets/images/wddd.png">
              <p>我的综评</p>
            </div>
            <div class="list">
              <div class="item">
                <div class="row flex">
                  <div class="left">
                    获取 综评升学报告
                  </div>
                  <div class="right">
                    <span>支付金额：</span>{{ (money / 100).toFixed(2) }}元
                  </div>
                </div>
                <div class="row-2 flex">
                  <div class="left">
                    数量：1
                  </div>
                  <div class="right">
                    待支付
                  </div>
                </div>
              </div>
            </div>
            <div class="use-point-area">
              <div class="flex">
                <div class="cur-point">当前积分：<span class="hl-point">{{ myPoint }}</span></div>
                <div class="point-tips">(1积分可兑换{{ exchange / 100 }}元)</div>
              </div>
              <div class="flex">
                <div>使用积分：</div>
                <n-input-number button-placement="both" v-model:value="usePoint" clearable :precision="0" :min="0" :max="myPoint"
                                @update:value="usePointChange"/>
              </div>
            </div>
            <div class="foot flex">
              <div class="btn" @click="submit">
                立即支付
              </div>
            </div>
          </div>
        </div>
        <Footer/>
        <n-modal v-model:show="showPay">
          <n-card
              style="width: 600px"
              title="微信扫码支付"
              :bordered="false"
              size="huge"
              role="dialog"
              aria-modal="true"
          >
            <div class="qr-code">
              <img :src="codeUrl" alt="" style="margin: 0 auto"/>
            </div>
          </n-card>
        </n-modal>
      </div>
    </n-layout-content>
  </n-layout>
</template>

<script setup>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import {useRoute, useRouter} from 'vue-router'
import {dictType, addPayment, myInfo, checkPaidState} from '@/api/api'
import {ref, onMounted, onUnmounted} from "vue";
import {useMessage} from "naive-ui";
import {setToken} from "@/util/auth";
import QRCode from 'qrcode'

const route = useRoute()
const router = useRouter()
const pointRule = ref([])
const exchange = ref(null);
const money = ref(9900);  //TODO:测试金额：99元
const price = ref(9900);  //TODO:测试金额：99元
const myPoint = ref({});
const usePoint = ref(0);
const message = useMessage()
const showPay = ref(false)
const codeUrl = ref('')
const paymentId = ref('')

let checkPaidStatus = null;

onUnmounted(() => {
  clearInterval(checkPaidStatus);
})

function submit() {
  addPayment({reviewId: route.query.id, usePoint: usePoint.value}).then(res => {
    console.log('addPayment result', res);
    if (res.code === 200) {
      if (res.data.paid === 1) {
        router.push('/report?id=' + route.query.id);
      } else {
        if (res.data.body && res.data.body.success) {
          console.log('展示支付二维码...')
          QRCode.toDataURL(res.data.body.codeUrl, {width: 300}, function (err, url) {
            showPay.value = true;
            codeUrl.value = url;
            paymentId.value = res.data.id;

            checkPaidStatus = setInterval(() => {
              checkPaidState(paymentId.value).then(res => {
                if (res.paid === 1) {
                  clearInterval(checkPaidStatus);
                  router.push('/report?id=' + route.query.id);
                }
              })
            }, 2000);
          })
        } else {
          message.error(res.data.body.error);
        }
      }
    }
  })
}

async function init() {
  let res = await dictType('/point_rule');
  pointRule.value = res.data
  let rule = pointRule.value.find(i => {
    return i.dictLabel === '1积分兑换人民币分'
  });
  exchange.value = Math.abs(rule.dictValue);

  let info = await myInfo();
  console.log('info', info);
  myPoint.value = info.point;
}

function usePointChange() {
  console.log('usePointChange', usePoint.value);
  money.value = price.value - usePoint.value * exchange.value;
  if (money.value < 0) {
    money.value = 0;
  }
}

function toPage(path) {
  router.push(path)
}

onMounted(async () => {
  await init();
  //默认不使用积分
})

</script>

<style lang="scss" scoped>
.breadcrumb {
  width: 37.5rem;
  margin: 0 auto;
  margin-top: 0.75rem;
}

.use-point-area {
  font-size: 0.5rem;
  background-color: #f9f9fc;
  padding: 0.5rem;

  .flex {
    justify-content: right;
  }

  .cur-point {
    margin-bottom: 0.2rem;

    .hl-point {
      color: #FF6E00;
      font-weight: bold;
    }
  }

  .point-tips {
    margin-left: 0.5rem;
    color: #999999;
  }

  .n-input-number {
    text-align: center;
    width: 5rem;
  }
}

.qr-code {
  img {
    border: 1px solid #e6e6e6;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);
    border-radius: 50px;
  }

  margin-bottom: 1rem;
}

.qr-code-tips {
  text-align: center;
  margin-top: 1rem;
}

.container {
  max-width: 37.5rem;
  min-height: 21.0625rem;
  margin: 0 auto;
  margin-top: 0.5rem;
  background: #FFFFFF;
  box-shadow: 0px 0 0.125rem 0px rgba(0, 0, 0, 0.09);
  border-radius: 0.25rem;
  padding: 0.71875rem;
  position: relative;

  .result {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 100%;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    img {
      width: 4.5rem;
    }

    p {
      width: 13.21875rem;
      font-size: 0.4375rem;
      font-family: SourceHanSansCN, SourceHanSansCN;
      color: #061B5C;
      text-align: center;
    }
  }

  .d-title {
    align-items: center;
    margin-bottom: 0.75rem;

    img {
      width: 0.75rem;
      height: 0.75rem;
      margin-right: 0.1875rem;
    }

    p {
      font-size: 0.53125rem;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: 600;
      color: #081D5D;
    }
  }

  .base-area {
    .a-title {
      font-size: 0.46875rem;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: 500;
      color: #333333;
      height: 1.3125rem;
      line-height: 1.3125rem;
      padding-left: 0.5rem;
      box-sizing: border-box;
      background: linear-gradient(270deg, rgba(255, 255, 255, 0.36) 0%, rgba(160, 178, 233, 0.36) 100%);
    }

    .info-awards {
      padding-bottom: 0.5rem;
      flex-wrap: wrap;

      .item {
        width: 8.125rem;
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
        background: #FFFFFF;
        border-radius: 0.125rem;
        border: 1px solid #ECECF3;
        margin-top: 0.75rem;
        cursor: pointer;
        position: relative;
        padding-bottom: 0.5rem;

        .checked {
          position: absolute;
          width: 0.6875rem;
          height: 0.625rem;
          background: #d14538;
          right: 0;

          svg {
            width: 0.6875rem;
            color: #fff;

          }
        }

        &.active {
          border-color: #d14538;
        }

        .head {
          font-size: 0.4375rem;
          font-family: SourceHanSansCN, SourceHanSansCN;
          font-weight: 500;
          color: #081D5D;
          height: 1.375rem;
          line-height: 1.375rem;
          border-bottom: 1px solid #ECECF3;
          padding: 0 0.5rem;
        }

        p {
          font-size: 0.4375rem;
          font-family: SourceHanSansCN, SourceHanSansCN;
          color: #333333;
          padding: 0 0.5rem;
          margin-top: 0.375rem;
          display: -webkit-box;
          -webkit-line-clamp: 3; /* 设置显示的行数 */
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }

    .info {
      flex-wrap: wrap;

      .item {
        padding: 0.5rem;

        .label {
          font-size: 0.46875rem;
          font-family: SourceHanSansCN, SourceHanSansCN;
          font-weight: 500;
          color: #081D5D;
        }

        .value {
          font-size: 0.46875rem;
          font-family: SourceHanSansCN, SourceHanSansCN;
          color: #333333;
          margin-top: 0.125rem;
        }
      }
    }


  }

  .list {
    .item {
      .row,
      .row-2 {
        justify-content: space-between;
      }

      .row {
        align-items: center;

        .left {
          font-size: 0.5rem;
          font-family: SourceHanSansCN, SourceHanSansCN;
          color: #061B5C;
        }

        .right {
          font-size: 0.8125rem;
          font-family: Rubik, Rubik;
          font-weight: 600;
          color: #061B5C;

          span {
            font-size: 0.4375rem;
          }
        }
      }

      .row-2 {
        .left,
        .right {
          font-size: 0.5rem;
          font-family: SourceHanSansCN, SourceHanSansCN;
          color: #999999;
        }

        .right {
          color: #FF6E00;
        }
      }

      background: #F9F9FC;
      border-radius: 0.1875rem;
      padding: 0.5rem 0.75rem;
      margin-bottom: 0.75rem;
    }
  }

  .foot {
    justify-content: flex-end;
    margin-top: 0.75rem;

    .btn {
      width: 5rem;
      height: 1.4375rem;
      background: #d14538;
      border-radius: 0.0625rem;
      font-size: 0.4375rem;
      font-family: SourceHanSansCN, SourceHanSansCN;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 1024px) {
  .breadcrumb {
    display: none;
  }
}

@media screen and (max-width: 680px) {
  .container {
    width: calc(100% - 30px);
    margin: 0 auto;
    margin-top: 15px;
  }
}

@media screen and (max-width: 610px) {
  .info-awards {
    .item {
      width: 100% !important;
    }
  }
}

.mt-25 {
  margin-top: 0.78125rem;
}

.mt-16 {
  margin-top: 0.5rem;
}
</style>