<template>
  <n-layout>
    <n-layout-content>
      <div class="bg2 h-full" v-if="loaded">
        <div class="print-fixed" @click="download">
          <img src="../../assets/images/download-pdf.png" alt="下载报告" title="下载报告"/>
        </div>
        <div class="full-loading" v-if="loading">
          <div class="loading-body">
            <n-spin size="large" stroke="#fdfdfd"/>
            <div>加载中，请稍后...</div>
          </div>
        </div>
        <div class="header2">
          <div class="logo2"></div>
          <div class="title">江苏省综合评价升学测评报告</div>
          <div class="sub-title">Jiangsu comprehensive evaluation report</div>
        </div>
        <div class="intro">
          <div class="title">引言</div>
          <div class="content text-indent">
            <p>
              为全面贯彻落实《国务院关于深化考试招生制度改革的实施意见》、《国务院关于新时代推进普通高中育人方式改革的指导意见》及《江苏省关于普通高校综合评价招生改革试点工作的通知》精神，深化考试招生制度改革，省教育厅积极推进综合评价升学改革试点工作。</p>
            <p>
              综合评价招生模式旨：综合考量考生高考文化分、高校考核结果、高中学业水平考试成绩、综合素质评价以及高校自身培养的特色要求等多个维度，建立的一种多位一体的人才选拔综合评价体系。</p>
            <p>
              本平台的开发，结合综合评价升学阳光高考网和学校填报系统的申报要求，对填报内容进行梳理和分类；根据江苏省最新综合评价录取招生高校的简章要求，对简章进行智能化记录、拆解和存储；以往年的最新录取结果为数据库，建立了江苏综评升学评测模型；根据学生填报的数据，进行自动化比较，生成专属的评测报告。本报告旨在帮助学生科学规划，查漏补缺；辅助精准施策，助力圆梦理想高校。</p>
            <p>此报告由南京大学、南京师范大学等多名专家联合研发，依据历年录取数据和考评经验，建立独特的“赋分制”评价规则，具有原创性和科学性。</p>
            <p class="c-2">距离高考还有{{ getCountdown() }}天，高考加油！</p>
          </div>
        </div>
        <div class="report-content report-content-1">
          <div class="report-header-title">
            <img src="../../assets/images/left-label-icon.png" alt=""/>
            <div class="report-title">基础信息</div>
            <img src="../../assets/images/right-label-icon.png" alt=""/>
          </div>
          <div class="report-block info">
            <div class=" info-grid flex">
              <div class="item">
                <div class="label">
                  学生姓名
                </div>
                <div class="value">
                  {{ model.baseName }}
                </div>
              </div>
              <div class="item">
                <div class="label">
                  性别
                </div>
                <div class="value">
                  {{ model.baseGender }}
                </div>
              </div>
              <div class="item">
                <div class="label">
                  年级
                </div>
                <div class="value">
                  {{ getTypeName(model.baseGrades, 'grades') }}
                </div>
              </div>
              <div class="item">
                <div class="label">
                  在读高中
                </div>
                <div class="value">
                  {{ getTypeName(model.gradeSchool, 'selectOptions3') }}
                </div>
              </div>
              <div class="item">
                <div class="label">
                  高中级别
                </div>
                <div class="value">
                  {{ getTypeName(model.gradeSchoolLevel, 'school') }}
                </div>
              </div>
              <div class="item">
                <div class="label">
                  身体素质
                </div>
                <div class="value">
                  {{ getTypeName(model.basePhysicalLevel, 'physical') }}
                </div>
              </div>
              <div class="item">
                <div class="label">
                  主选科目
                </div>
                <div class="value">
                  {{ getTypeName(model.basePrimarySubject, 'primary') }}
                </div>
              </div>
              <div class="item">
                <div class="label">
                  选修科目
                </div>
                <div class="value">
                  {{ getTypeName(model.baseElectiveSubject, 'elective') }}
                </div>
              </div>
              <div class="item">
                <div class="label">
                  预估分数
                </div>
                <div class="value">
                  {{ model.baseScore }}
                </div>
              </div>
              <div class="item">
                <div class="label">
                  年级排名
                </div>
                <div class="value">
                  {{ getTypeName(model.gradeScoreLevel, 'score') }}
                </div>
              </div>
              <div class="item">
                <div class="label">
                  英语能力
                </div>
                <div class="value">
                  {{ getTypeName(model.baseEnglishLevel, 'english') }}
                </div>
              </div>
              <div class="item">
                <div class="label">
                  意向专业
                </div>
                <div class="value">
                  {{ getTypeName(model.baseMajor, 'selectOptions2') }}
                </div>
              </div>
            </div>
            <div class="item" style="margin-top: 10px">
              <div class="label">
                意向高校
              </div>
              <div class="value">
                {{ getTypeName(model.baseUniversityId, 'selectOptions1') }}
              </div>
            </div>
          </div>
          <div class="report-header-title">
            <div class="report-title">综合素质评价档案</div>
          </div>
          <div class="report-block report-block-has-header">
            <div class="title-container">
              <div class="block-title">&nbsp;&nbsp;&nbsp;{{ getAwardScoreResult() }}&nbsp;&nbsp;&nbsp;</div>
            </div>
            <p class="text-indent">综合素质评价档案作为强基计划、综合评价录取、出国留学等重要参考依据，其中在综合评价录取总成绩中占有一定权重，请广大考生关注综合素质评价档案的完成程度。依据您所填写的信息，综合素质评价档案评级为
              <span class="c-1 f-w">“{{ getAwardScoreResult() }}”</span>。</p>
            <p class="c-1 f-w text-indent">如果您希望在综合评价升学中更有竞争优势，建议您补充{{ getSuggestedAward() }}。</p>
          </div>
          <div class="report-block">
            <div id="myEcharts2" class="chart-area"></div>
          </div>
        </div>
        <div class="report-content report-content-2">
          <div class="report-header-title">
            <img src="../../assets/images/left-label-icon.png" alt=""/>
            <div class="report-title">综合评价升学报告</div>
            <img src="../../assets/images/right-label-icon.png" alt=""/>
          </div>
          <div class="report-block report-block-has-header bg-color-1">
            <div class="title-container">
              <div class="block-title">&nbsp;&nbsp;&nbsp;您符合 {{ uniqueUniversityList.length }} 所院校&nbsp;&nbsp;&nbsp;</div>
            </div>
            <p class="tc font-fff" v-if="uniqueUniversityList.length>0">依据江苏省综评院校最新招生章程，您符合 {{
                uniqueUniversityList.length
              }}
              所院校的报名要求，具体结果如下：</p>
            <p class="tc font-fff" v-else>
              依据江苏省综评院校最新招生章程，您符合 0 所院校的报名要求，建议您补充综合素质评价档案的相关材料，从而提升竞争力。匹配结果如下：
            </p>
          </div>
          <div class="table-2">
            <table>
              <thead>
              <tr>
                <td>院校名称</td>
                <td>成绩优异</td>
                <td>五大学科</td>
                <td>全国竞赛</td>
                <td>全省竞赛</td>
                <td>其他竞赛</td>
                <td>荣誉表彰</td>
                <td>创新潜质</td>
              </tr>
              </thead>
              <tbody v-if="uniqueUniversityList.length>0">
              <tr v-for="item in uniqueUniversityList">
                <td>{{ item.name }}</td>
                <td v-for="repo in report">
                  <div class="td-area">
                    <div class="check" v-if="checkUniversity(item.name,repo)"></div>
                    <div class="none" v-else>x</div>
                  </div>
                </td>
              </tr>
              </tbody>
              <tbody v-else>
              <tr v-for="item in types.selectOptions1">
                <td>{{ item.name }}</td>
                <td v-for="repo in report">
                  <div class="td-area">
                    <div class="none">x</div>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
            <div class="legend">
              <div class="check"></div>
              是符合条件
              <div class="none">x</div>
              是不符合条件
            </div>
          </div>
          <div class="report-header-title">
            <div class="report-title">综合评价升学能力分析</div>
          </div>
          <div v-for="item in report">
            <div class="report-block" v-if="item.universityList.length>0">
              <div v-if="item.id==='2'">
                <div class="report-title-sub">{{ item.title2 }}适配院校</div>
                <div class="report-block report-block-has-header bg-color-2">
                  <div class="title-container">
                    <div class="block-title w-100">{{ getTypeName(model.gradeScoreLevel, 'score') }}</div>
                  </div>
                  <p class="tc">您的成绩排名在同类选科年级排名{{
                      getTypeName(model.gradeScoreLevel, 'score')
                    }},并且{{ getTypeName(model.gradeSchool, 'selectOptions3') }}为{{
                      getTypeName(model.gradeSchoolLevel,
                          'school')
                    }}</p>
                </div>
              </div>
              <div v-else>
                <div class="report-title-sub">{{ item.title2 }}适配院校</div>
                <div class="report-block-flex">
                  <div class="report-block report-block-has-header report-block-h-title bg-color-2"
                       v-for="award in getAwardsByType(item.id)">
                    <div class="title-container">
                      <div class="block-title w-100">{{
                          getTypeName(award.raceCategory, 'competition', award.raceName)
                        }}
                      </div>
                    </div>
                    <p class="tc">您已获得{{ getTypeName(award.raceCategory, 'competition', award.raceName) }}
                      {{ buildAwardLevel(award) + buildAwardRank(award) }}</p>
                  </div>
                </div>
              </div>
              <div class="report-block report-block-has-header bg-color-2">
                <div class="title-container">
                  <div class="block-title w-100">您符合{{ item.universityList.length }}所院校</div>
                </div>
                <p class="tc" v-if="item.id==='2'">依据江苏省综评院校最新招生章程，您可以通过成绩优异来报名以下院校，具体结果如下：</p>
                <p class="tc" v-else-if="item.id==='7'">依据江苏省综评院校最新招生章程，您可以通过荣誉表彰来申报以下院校，具体结果如下:</p>
                <p class="tc" v-else-if="item.id==='8'">依据江苏省综评院校最新招生章程，您可以通过创新潜质来申报以下院校，具体结果如下:</p>
                <p class="tc" v-else>依据江苏省综评院校最新招生章程，您可以通过竞赛奖项来申报以下院校，具体结果如下：</p>
              </div>
              <table class="table-1">
                <thead>
                <tr>
                  <td>院校名称</td>
                  <td v-if="item.id==='2'">成绩要求</td>
                  <td v-else>奖项要求</td>
                </tr>
                </thead>
                <tbody>
                <tr v-for="i in item.universityList">
                  <td style="width: 5rem">{{ i.name }}</td>
                  <td v-if="item.id==='2'">{{ i.scoreDesc }}</td>
                  <td v-else>{{ buildAwardList(item, i) }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="report-header-title">
            <img src="../../assets/images/left-label-icon.png" alt=""/>
            <div class="report-title">意向院校分析</div>
            <img src="../../assets/images/right-label-icon.png" alt=""/>
          </div>
          <div class="report-block">
            <div class="report-block report-block-has-header bg-color-2">
              <div class="title-container">
                <div class="block-title">{{ getTypeName(model.baseUniversityId, 'selectOptions1') }}</div>
              </div>
              <p class="tc">您的意向学校是{{
                  getTypeName(model.baseUniversityId, 'selectOptions1')
                }}，根据您的综合评价档案和江苏省各院校最新招生章程分析，您的意向院校匹配情况如下：</p>
            </div>
            <div class="table-2">
              <table>
                <thead>
                <tr>
                  <td>院校名称<br/>(排名不分先后)</td>
                  <td>成绩优异</td>
                  <td>五大学科</td>
                  <td>全国竞赛</td>
                  <td>全省竞赛</td>
                  <td>其他竞赛</td>
                  <td>荣誉表彰</td>
                  <td>创新潜质</td>
                  <td>您的信息</td>
                  <td>往届投档线</td>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in wouldUniversityList">
                  <td>{{ item.name }}</td>
                  <td v-for="repo in report">
                    <div class="td-area">
                      <div class="check" v-if="item[repo.id]"></div>
                      <div class="none" v-else>x</div>
                    </div>
                  </td>
                  <td>{{ model.baseScore }}</td>
                  <td>{{ getScoreLine(item) }}</td>
                </tr>
                </tbody>
              </table>
              <div class="legend">
                <div class="check"></div>
                是符合条件
                <div class="none">x</div>
                是不符合条件
              </div>
            </div>
          </div>
        </div>

        <div class="report-content report-content-1">
          <div class="report-header-title">
            <img src="../../assets/images/left-label-icon.png" alt=""/>
            <div class="report-title">冲、稳院校建议</div>
            <img src="../../assets/images/right-label-icon.png" alt=""/>
          </div>
          <div class="report-block">
            <div class="report-block report-block-has-header bg-color-1">
              <div class="title-container">
                <div class="block-title">&nbsp;&nbsp;&nbsp;预估分数{{ model.baseScore }}&nbsp;&nbsp;&nbsp;</div>
              </div>
              <p class="tc font-fff" v-if="getMatchUniversityList(1).length!==0||getMatchUniversityList(2).length!==0">
                根据您的预估分数和江苏省各综评院校往届最低投档线进行分析，给您建议冲、稳院校的如下：</p>
              <p class="tc font-fff" v-else>
                根据您的预估分数和江苏省各综评院校往届最低投档线进行分析，没有合适的冲、稳院校。建议您注重学科成绩的提升以及综合素质评价档案材料的增补。</p>
            </div>
            <div v-if="getMatchUniversityList(1).length!==0||getMatchUniversityList(2).length!==0">
              <div v-for="type in [1,2]">
                <div class="adv-desc">建议<span class="hl">{{ type === 1 ? '冲' : '稳' }}</span>
                  的院校(<span class="hl">{{ getMatchUniversityList(type).length }}</span>所)
                </div>
                <div class="table-2">
                  <table>
                    <thead>
                    <tr>
                      <td>院校名称</td>
                      <td>成绩优异</td>
                      <td>五大学科</td>
                      <td>全国竞赛</td>
                      <td>全省竞赛</td>
                      <td>其他竞赛</td>
                      <td>荣誉表彰</td>
                      <td>创新潜质</td>
                      <td>往届投档线</td>
                      <td>匹配度</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in getMatchUniversityList(type)">
                      <td>{{ item.name }}</td>
                      <td v-for="repo in report">
                        <div class="td-area">
                          <div class="check" v-if="checkUniversity(item.name,repo)"></div>
                          <div class="none" v-else>x</div>
                        </div>
                      </td>
                      <td>{{ getScoreLine(item) }}</td>
                      <td v-if="type===1">{{ item.match1 }}</td>
                      <td v-if="type===2">{{ item.match2 }}</td>
                    </tr>
                    </tbody>
                  </table>
                  <div class="legend">
                    <div class="check"></div>
                    是符合条件
                    <div class="none">x</div>
                    是不符合条件
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="report-content report-content-2" v-if="hasAwardCount>0">
          <div class="report-header-title">
            <img src="../../assets/images/left-label-icon.png" alt=""/>
            <div class="report-title">考生奖项信息</div>
            <img src="../../assets/images/right-label-icon.png" alt=""/>
          </div>

          <div v-for="awardType in awardTypes">
            <div class="report-block" v-if="awardType.awards.length>0">
              <div class="report-title-sub">
                {{ awardType.name2 }}
              </div>
              <div class="info-awards flex">
                <div class="item" v-for="award in awardType.awards">
                  <p><span class="label">奖项名称：</span></p>
                  <p><span class="label">{{ getTypeName(award.raceCategory, 'competition', award.raceName) }}</span></p>
                  <div v-if="awardType.id==='8'">
                    <p v-if="award.file"><span class="label">附件：</span><a target="_blank" :href="getFile(award.file)">查看附件</a></p>
                    <p v-if="award.raceDate"><span class="label">发证日期：</span>{{ award.raceDate }}</p>
                  </div>
                  <div v-else>
                    <p v-if="awardType.id==='9'"><span class="label">奖项类别：</span><span
                        class="hl f-w">{{ getAdvantageType(award.advantageType) }}</span></p>
                    <p v-if="award.raceLevel"><span class="label">奖项级别：</span><span class="hl f-w">{{ buildAwardLevel(award) }}</span>
                    </p>
                    <p v-if="award.raceRank"><span class="label">奖项等级：</span><span class="hl f-w">{{ buildAwardRank(award) }}</span></p>
                    <p v-if="award.raceOrg"><span class="label">主办单位：</span>{{ award.raceOrg }}</p>
                    <p v-if="award.raceNum"><span class="label">证书编号：</span>{{ award.raceNum }}</p>
                    <p v-if="award.raceDate"><span class="label">发证日期：</span>{{ award.raceDate }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="report-content report-content-1" v-if="uniqueUniversityList.length>0">
          <div class="report-header-title">
            <img src="../../assets/images/left-label-icon.png" alt=""/>
            <div class="report-title">综评适配院校详情</div>
            <img src="../../assets/images/right-label-icon.png" alt=""/>
          </div>

          <div class="university-list-area">
            <div v-for="item in uniqueUniversityList" class="university-list-item">
              <div class="university-name">{{ item.name }}</div>
              <div class="university-label">院校特点：</div>
              <div class="university-content">{{ item.feature.length > 50 ? item.feature.substr(0, 50) + "..." : item.feature }}</div>
              <div class="university-block">
                <div class="university-label">成绩要求：</div>
                <div class="university-content t-hidden">{{ item.scoreDesc }}</div>
              </div>
              <div class="university-block">
                <div class="university-label">匹配度：</div>
                <n-rate readonly :default-value="getMatchValue(item.id)" color="#d14538" class="university-star"/>
              </div>
            </div>
          </div>
        </div>

        <div class="bg-bottom">
          <div class="bottom-content">
            <div class="title tc">结语</div>
            <div class="content-desc text-indent">
              <p>
                综合评价升学不仅关注终结性评价的初审和复试（校测），还需关注高中阶段前五个学期的过程性评价，是一个复杂和长周期的过程，也是广大学子在升学道路上实现“弯道超车”的重要机会。</p>
              <p>
                我们衷心地希望本次测评能为您的综合评价升学提供科学、详细的参考意见，帮助您全面、客观地了解各院校综评录取条件和自身现状，认识自己的不足和优势。</p>
              <p>
                在接下来的升学道路上，我们建议您积极参加感兴趣的各类竞赛活动（以教育部和省教育厅发布的竞赛为佳）、积极参加高质量的研究性学习课题、体育活动、艺术素养活动、社会实践活动；认真准备各类材料，充分展示自己的真实水平和能力；努力学习，提升成绩，争取冲刺更高层次的院校。</p>
              <p>
                最后，我们建议您经常与自己的规划师沟通和交流，根据自己的兴趣和爱好，科学规划，精准施策，早做准备。相信通过您的坚持和努力，定会圆梦理想名校。</p>
              <p>鸿鹄高飞志千里，自古英雄出少年。</p>
            </div>
          </div>
        </div>
      </div>
    </n-layout-content>
  </n-layout>
</template>

<script setup>
import mixin from "@/views/Report/mixin";
import {onMounted, ref} from "vue";
import {downloadPdfUrl, getDownloadPdfUrl} from "@/api/api";

const {
  router,
  route,
  loaded,
  load,
  model,
  reportData,
  uniqueUniversityList,
  report,
  wouldUniversityList,
  hasAwardCount,
  awardTypes,
  types,
  id,
  initChart2,
  getAwardScoreResult,
  getAwardsByType,
  getCountdown,
  getSuggestedAward,
  getMatchValue,
  getScoreLine,
  checkScMatch,
  scRangeCheck,
  getMatchUniversityList,
  getWouldUniversityList,
  getFile,
  checkUniversity,
  buildAwardList,
  getAdvantageType,
  buildAwardLevel,
  buildAwardRank,
  getTypeName,
  getUniversity,
  toPage,
} = mixin()

const loading = ref(false);

onMounted(async () => {
  await load();
})

function download() {
  loading.value = true;
  getDownloadPdfUrl(id.value).then(res => {
    loading.value = false;
    window.open(process.env.VUE_APP_BASE_API + res, "_self");
  })
}

</script>

<style lang="scss" scoped>
@import "index";
</style>
