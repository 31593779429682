<template>
  <div class="footer">
    <div class="red">
      <img src="/img/footer-logo.png">
      <div class="contact-number">联系电话：400-655-7288 / 025-58229930</div>
    </div>
  </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
.footer {
  .red {
    height: 4.625rem;
    background-color: #d14538;
    padding-top: 1.0625rem;
    box-sizing: border-box;

    img {
      width: 7.0625rem;
      margin-left: 1.25rem;
    }

    .contact-number {
      color: #fff;
      font-size: 0.5rem;
      font-weight: bold;
      margin-top: 1rem;
      text-align: left;
      margin-left: 1.25rem;
    }
  }
}
</style>
