import {createStore} from 'vuex'
import userinfo from './modules/user'

export default createStore({
    state: {},
    getters: {},
    mutations: {},
    actions: {},
    modules: {
        userinfo
    }
})
