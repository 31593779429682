<template>
  <n-layout>
    <n-layout-content>
      <div class="bg h-full">
        <Header/>
        <div style="min-height: calc(100% - 5.875rem); padding-bottom: 1.25rem;">
          <h1 class="title">江苏省高中生综评升学 AI自主测评</h1>
          <p class="intro text-center">
            本测评依据最新《江苏省关于普通高校综合评价招生改革试点工作的通知》和江苏省综合评价录取招生高校最新招生简章及录取结果。</p>
          <div class="btns">
            <a class="red" href="javascript:void(0)" @click="router.push('/Evaluation/intro')">
              <h2>测前须知</h2>
              <p>测前准备</p>
            </a>
            <a class="red" href="javascript:void(0)" @click="router.push('/Evaluation?type=1')">
              <h2>我要测评</h2>
              <p>获取综评升学报告</p>
            </a>
            <a class="red" href="http://www.jshzycedu.com/portal/course.html">
              <h2>课程学习</h2>
              <p>系统课程学习</p>
            </a>
            <a class="red" href="/Home#university-area">
              <h2>适配院校</h2>
              <p>奖项适配院校</p>
            </a>
          </div>
          <div class="breadcrumb">
            <n-breadcrumb>
              <n-breadcrumb-item @click="toHome" separator=">">首页</n-breadcrumb-item>
              <n-breadcrumb-item v-if="cid" @click="toSchoolList(cid)" separator=">">{{
                  curLabel
                }}
              </n-breadcrumb-item>
              <n-breadcrumb-item>{{ curName }}</n-breadcrumb-item>
            </n-breadcrumb>
          </div>
          <div class="schools">
            <div class="d-title">
              {{ curName }}
            </div>
            <div class="intro ql-editor" v-html="curData.detail">

            </div>
          </div>
        </div>
        <Footer/>
      </div>
    </n-layout-content>
  </n-layout>
</template>

<script setup>
import {onMounted, ref} from 'vue'
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import {useRouter, useRoute} from 'vue-router'
import {dictType, universityDetail} from '@/api/api'

const router = useRouter()
const route = useRoute()
const curName = ref('')
const curLabel = ref('')
const curData = ref({})
const cid = ref(route.query.cid)

universityDetail(route.query.id).then(res => {
  curName.value = res.data.name
  res.data.detail = res.data.detail.replaceAll('/dev-api', process.env.VUE_APP_BASE_API);
  res.data.detail = res.data.detail.replaceAll('/prod-api', process.env.VUE_APP_BASE_API);
  curData.value = res.data
})
dictType('/hz_university_level').then(res => {
  res.data.forEach((item, i) => {
    if (item.dictValue == route.query.cid) {
      curLabel.value = item.dictLabel
    }
  });
})

function toHome() {
  router.push('/home')
}

function toSchoolList(id) {
  router.push('/school/' + id)
}

</script>

<style lang="scss">
.ql-editor {
  text-indent: 1rem;
  font-size: 0.5rem !important;

  img {
    margin: 0 auto;
  }
}
</style>

<style lang="scss" scoped>
.breadcrumb {
  width: 37.5rem;
  margin: 0 auto;
  margin-top: 0.75rem;
}

.title {
  font-size: 1.25rem;
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: bold;
  color: #061B5C;
  line-height: 1.8125rem;
  letter-spacing: 0.03125rem;
  text-align: center;
  margin-top: 1.96875rem;
  margin-bottom: 0.625rem;
}

.intro {
  font-size: 0.375rem;
  font-family: SourceHanSansCN, SourceHanSansCN;
  color: #666666;
  max-width: 29.84375rem;
  margin: 0 auto;
  margin-bottom: 1.0625rem;

  img {
    margin: 0 auto;
  }
}

.btns {
  display: flex;
  justify-content: center;

  a {
    width: 5.25rem;
    height: 1.8125rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 0.0625rem;

    h2 {
      font-size: 0.5625rem;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: bold;
      color: #FFFFFF;
    }

    p {
      font-size: 0.375rem;
      font-family: SourceHanSansCN, SourceHanSansCN;
      color: #FFFFFF;
    }
  }

  .red {
    background: linear-gradient(270deg, #d14538 0%, #F37457 100%);
    margin-right: 0.78125rem;
  }

  .blue {
    background: linear-gradient(270deg, #061B5C 0%, #33457B 100%);
  }
}

.schools {
  width: 37.5rem;
  min-height: 21.0625rem;
  margin: 0 auto;
  margin-top: 0.5rem;
  padding: 0.71875rem;
  background: #FFFFFF;
  box-shadow: 0px 0 0.125rem 0px rgba(0, 0, 0, 0.09);
  border-radius: 0.25rem;
  font-size: 0.4375rem;
  font-family: SourceHanSansCN, SourceHanSansCN;

  .d-title {
    font-size: 1.25rem;
    font-family: SourceHanSansCN, SourceHanSansCN;
    font-weight: bold;
    color: #061B5C;
    line-height: 1.8125rem;
    letter-spacing: 0.03125rem;
    text-align: center;
  }

  .intro {
    margin-top: 0.625rem;

    img {
      margin: 0 auto;
    }
  }
}

@media screen and (max-width: 961px) {
  .breadcrumb {
    display: none;
  }

  .schools {

    width: calc(100% - 30px);
    margin: 0 auto;
    margin-top: 15px;
  }
}

@media screen and (max-width: 781px) {
  .schools {
    width: calc(100% - 30px);
    margin: 0 auto;
    margin-top: 15px;

    .list {
      .item {
        width: calc(50% - 1rem);
      }
    }
  }
}

@media screen and (max-width: 531px) {
  .schools {
    width: calc(100% - 30px);
    margin: 0 auto;
    margin-top: 15px;

    .list {
      .item {
        width: 100%;
      }
    }
  }
}

.mt-25 {
  margin-top: 0.78125rem;
}

.mt-16 {
  margin-top: 0.5rem;
}
</style>
