import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import naive from 'naive-ui'
import './util/flexible'
import './assets/css/reset.css'
import './assets/css/viewer.css'

let app = createApp(App)
app.config.warnHandler = (msg, instance, trace) => {
}
app.use(naive).use(store).use(router).mount('#app')
